import React from 'react';
import AuthenticationService from '../services/AuthenticationService';
import BackendService from '../services/BackendService';

const auth = new AuthenticationService();

class CustomStyleLoader extends React.Component {

  constructor({t, props}) {
    super(props);

    this.hasRendered = false;

    if (!auth.isLoggedIn()) {
      return;
    }

    this.updateState = this.updateState.bind(this);

    this.backend = new BackendService();

    this.backend.registerCallback(this.updateState);

    if (this.backend.get('reservations')) {
      let reserv = this.backend.get('reservations')[0] || {};

      this.state = {
        styles_url: reserv.externalStyleSheetURL || '',
        reservation: reserv.externalReservationNo || '',
      };
    }
    else {
      this.state = {
        styles_url: null,
        reservation: null,
      };
    }

    this.t = t;
  }

  componentDidMount() {
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  updateState() {

    // only update if response not empty, component still mounted and it hasn't been already rendered (flickering!)
    if (this.backend.get('reservations') && this._ismounted && !this.hasRendered) {

      let reserv = this.backend.get('reservations')[0] || {};

      this.setState({
        styles_url: reserv.externalStyleSheetURL || '',
        reservation: reserv.externalReservationNo || '',
      });

      this.hasRendered = true;
    }
  }

  render() {

    // default layout: show the otherwise flickering nav bar
    let defaultlayout = <div>
      <style type={'text/css'}>
        {'html body nav.navbar { display: flex; }'}
      </style>
    </div>;

    if (!auth.isLoggedIn()) {
          return defaultlayout; // if not logged in use default layout
    }

    let styleurl;

    if (this.state.styles_url) {
      styleurl = this.state.styles_url.replace('{reservation}',
        this.state.reservation);
    }
    else {
      styleurl = null;
    }

    if (styleurl !== '' && styleurl !== null) {
      return (
        <div>
          <link rel={'stylesheet'} href={styleurl}/>
        </div>
      );
    }
    else {
      if(this.state.reservation) { // make sure something was returned from server (when logged in) before using default
        return defaultlayout;
      } else {
        return null;
      }
    }
  }
}

export default CustomStyleLoader;
