import React from 'react';
import {withNamespaces} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const GuestInfoText = ({t, textTitle = 'Hinweis', hiddenUntil = null, textBody = null, textLinkTitle = null, textLinkTarget = null}) => {

  let dt = new Date(hiddenUntil);
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  let dts = hiddenUntil!==null ? dt.toLocaleDateString(undefined, options) : false;

  return (
    <div className={'col-12 p-4 guestinfo-box mb-2'}>
      {dts && <div className={'guestinfo-hidden-badge float-right p-1'}>
        <FontAwesomeIcon icon={['fad', 'lock-open']} className={'mr-1'}/>
        {t('app.guestinfo.hiddenUntil')} {dts}
      </div>
      }
      <b className={dts ? 'guestinfo-is_hidden' : ''}>
        {dts &&
          <FontAwesomeIcon icon={['fad', 'lock']} className={'mr-2'}/>
        }
        {textTitle}
      </b><br />
      {dts &&
        <span className={'guestinfo-locked-note text-darksecondary'}>{t('app.guestinfo.locked-note', {date: dts})}</span>
      }
      {textBody &&
        <div><br />{textBody}</div>
      }
      {textLinkTarget &&
        <div>
          <br/><br/>
          <a target={'_blank'} rel={'noopener noreferrer'} href={textLinkTarget} className={'btn btn-secondary text-light float-left'}>{textLinkTitle || t('app.guestinfo.textLinkNameFallback')}</a>
        </div>
      }
    </div>
  );

};

export default withNamespaces()(GuestInfoText);
