import React from 'react';
import {withNamespaces} from 'react-i18next';

class LabelValueColumns extends React.Component {
  static defaultProps = {
    labelClassName: '',
    valueClassName: '',
  };

  render() {
    return (
      <>
        <div className={'col-6 ' + this.props.labelClassName ?? ''}>
          {this.props.label}
        </div>
        <div className={'col-6 ' + this.props.valueClassName ?? ''}>
          {this.props.value}
        </div>
      </>
    );
  }
}

export default withNamespaces()(LabelValueColumns);
