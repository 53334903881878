import React from 'react';
import AlertBox from './elements/AlertBox';
import AuthenticationService from './services/AuthenticationService';
import {withNamespaces} from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImprintNotice from './elements/ImprintNotice';
import TopNav from './elements/TopNav';
import {Helmet} from "react-helmet";

const auth = new AuthenticationService();

class Login extends React.Component {

  constructor({t, props}) {
    super(props);
    this.state = {
      booking_no: '',
      email: '',
      password: '',
      lastError: '',
    };

    this.t = t;

    this.handleChange = this.handleChange.bind(this);
    this.performLogin = this.performLogin.bind(this);
    this.installListeners = this.installListeners.bind(this);
    this.initializeState = this.initializeState.bind(this);

    if(auth.isLoggedIn()){
      window.location.href = 'overview';
      return;
    }

  }

  initializeState() {
    if(URLSearchParams){ // not supported in IE (but Edge)
      let params = new URLSearchParams(window.location.search);
      this.setState({
        booking_no: params.get('reservation') || '',
        email: params.get('mail') || '',
        password: '',
        lastError: '',
      });
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  performLogin(event) {
    const username = this.state.booking_no + '@' + this.state.email;

    auth.login(username, this.state.password).then(() => {
      window.location.href = '/overview';
    }).catch((reason) => {
      this.setState({lastError: reason});
    });

  }

  installListeners(){
    const form = document.getElementById('loginform');
    const that = this;

    form.addEventListener('keyup', function(event){
      if(event.code === "Enter"){
        event.preventDefault();
        that.performLogin(event);
      }
    });
  }

  componentDidMount() {
    this.installListeners();
    this.initializeState();
  }

  componentDidUpdate() {
    this.installListeners();
  }

  render() {
    return (
      <div className="d-flex flex-column bg-light">
        <Helmet defer={false}>
          <title>Gast App - Login</title>
        </Helmet>
        <TopNav />

          <div className="container mt-3 p-3" id={'loginform'}>
            <AlertBox message={this.state.lastError}/>
            <p className="form-text">{this.t('login.welcome')}</p>
            <div className="form-group">
              <label htmlFor="booking_no">{this.t('login.booking_no')}</label>
              <input type="number" className="form-control" id="booking_no"
                     name="booking_no" value={this.state.booking_no}
                     onChange={this.handleChange} required/>
            </div>
            <div className="form-group">
              <label htmlFor="email">{this.t('login.email')}</label>

              <input type="email" className="form-control" id="email"
                     name="email"
                     value={this.state.email} onChange={this.handleChange}
                     required/>
            </div>
            <div className="form-group">
              <label htmlFor="password">{this.t('login.password')}</label>

              <input type="password" className="form-control" id="password"
                     name="password"
                     value={this.state.password} onChange={this.handleChange}
                     required/>
            </div>
            <div className="row">
              <div className="col-12">
                <button className="btn w-100 btn-secondary text-white login_button" onClick={this.performLogin}>
                  <FontAwesomeIcon icon={['fad', 'sign-in-alt']} />&nbsp;{this.t(
                  'login.loginbutton')}</button>
              </div>
            </div>
          </div>

        <ImprintNotice />
      </div>
    );
  }
}

export default withNamespaces()(Login);
