import React from 'react';
import log_error from './ErrorLogger';
import AuthenticationService from './AuthenticationService';
import {PlaceOfPayment} from '../enum/PlaceOfPayment';

const auth = new AuthenticationService();

export default class BackendService extends React.Component {

  constructor(props) {
    super(props);

    auth.requireLogin();

    this.apiResponses = {};

    this.handleResponse = this.handleResponse.bind(this);
    this.handleReservationsResponse = this.handleReservationsResponse.bind(this);
    this.handlePropertyResponse = this.handlePropertyResponse.bind(this);
    this.handlePropertyImageResponse = this.handlePropertyImageResponse.bind(this);
    this.handlePaymentsResponse = this.handlePaymentsResponse.bind(this);
    this.handleServicesResponse = this.handleServicesResponse.bind(this);
    this.handleHostResponse = this.handleHostResponse.bind(this);
    this.handlePropertyEquipmentGridResponse = this.handlePropertyEquipmentGridResponse.bind(this);
    this.handleCustomLinksResponse = this.handleCustomLinksResponse.bind(this);
    this.handleGuestInfoResponse = this.handleGuestInfoResponse.bind(this);
    this.triggerCallbacks = this.triggerCallbacks.bind(this);

    this.callback = null;

    this.fetchData();

  }

  registerCallback(func){
    this.callback = func;
  }

  triggerCallbacks(){
    if(this.callback){
      this.callback();
    }
  }

  fetchData() {
    this.fetchReservations();
    this.fetchProperty();
    this.fetchPropertyImage();
    this.fetchPayments();
    this.fetchServices();
    this.fetchHost();
    this.fetchPropertyEquipmentGrid();
    this.fetchCustomLinks();
    this.fetchGuestInfo();
  }

  get(datatype){
    return this.apiResponses[datatype];
  }

  fetchCustomLinks(){
      const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getCurrentUser().JWT }
      };

      const url = new URL(process.env.REACT_APP_API_ENTRYPOINT + '/../api/custom_links');

      return fetch(url.toString(), requestOptions)
          .then(this.handleResponse)
          .then(this.handleCustomLinksResponse)
          .catch((error) => {
             log_error('warn', 'BackendService', error);
             return Promise.reject('backend.fetch.failed.custom_links');
          });
  }

  fetchGuestInfo(){
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getCurrentUser().JWT }
    };

    const url = new URL(process.env.REACT_APP_API_ENTRYPOINT + '/../api/guest_infos');

    return fetch(url.toString(), requestOptions)
    .then(this.handleResponse)
    .then(this.handleGuestInfoResponse)
    .catch((error) => {
      log_error('warn', 'BackendService', error);
      return Promise.reject('backend.fetch.failed.guest_infos');
    });
  }

  handleCustomLinksResponse(response){
    this.apiResponses['customLinks'] = response['hydra:member'];
    this.triggerCallbacks();
  }

  handleGuestInfoResponse(response){
    this.apiResponses['guestInfo'] = response['hydra:member'];
    this.triggerCallbacks();
  }

  fetchPropertyEquipmentGrid() {
      const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getCurrentUser().JWT }
      };

      const url = new URL(process.env.REACT_APP_API_ENTRYPOINT + '/../api/property_equipment_grids');

      return fetch(url.toString(), requestOptions)
          .then(this.handleResponse)
          .then(this.handlePropertyEquipmentGridResponse)
          .catch((error) => {
             log_error('warn', 'BackendService', error);
             return Promise.reject('backend.fetch.failed.propertyEquipmentGrid');
          });
  }

  handlePropertyEquipmentGridResponse(response){
    this.apiResponses['propertyEquipmentGrid'] = response['hydra:member'];
    this.triggerCallbacks();
  }


  fetchHost() {
      const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getCurrentUser().JWT }
      };

      const url = new URL(process.env.REACT_APP_API_ENTRYPOINT + '/../api/hosts');

      return fetch(url.toString(), requestOptions)
          .then(this.handleResponse)
          .then(this.handleHostResponse)
          .catch((error) => {
             log_error('warn', 'BackendService', error);
             return Promise.reject('backend.fetch.failed.host');
          });
  }

  handleHostResponse(response){
    this.apiResponses['host'] = response['hydra:member'];
    this.triggerCallbacks();
  }

  fetchServices() {
      const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getCurrentUser().JWT }
      };

      const url = new URL(process.env.REACT_APP_API_ENTRYPOINT + '/../api/reservation_attached_services');

      return fetch(url.toString(), requestOptions)
          .then(this.handleResponse)
          .then(this.handleServicesResponse)
          .catch((error) => {
             log_error('warn', 'BackendService', error);
             return Promise.reject('backend.fetch.failed.reservationAttachedServices');
          });
  }

  handleServicesResponse(response){
    let items = response['hydra:member'];
    const sumByPlaceOfPaymentAndCurrency = (data) => {
      const summary = {
        atHost: [],
        atAgency: []
      };

      data.forEach(item => {
        // Because how we display the prices on the payment overview, we have to not include the deposit in the summary
        if (item.placeOfPayment !== null && item.name !== "Kaution") {
          const key = item.placeOfPayment.includes('atHost') ? 'atHost' : 'atAgency';
          const currency = item.currency;

          const currencyGroup = summary[key].find(group => group.currency === currency);

          // item.price is always EUR, item.convertedPrice is the price in the currency of the host
          if (currencyGroup) {
            currencyGroup.total += (currency === "EUR" ? item.price : item.convertedPrice);
          } else {
            summary[key].push({currency: currency, total: (currency === "EUR" ? item.price : item.convertedPrice)});
          }
        }
      });

      return summary;
    }

    const filtered = items.filter((item) => item.placeOfPayment !== PlaceOfPayment.ADVANCE_PAYMENT)

    this.apiResponses['reservationAttachedServices'] = response['hydra:member'];
    this.apiResponses['reservationAttachedServicesSummary'] = sumByPlaceOfPaymentAndCurrency(filtered);
    this.triggerCallbacks();
  }

  fetchPayments() {
      const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getCurrentUser().JWT }
      };

      const url = new URL(process.env.REACT_APP_API_ENTRYPOINT + '/../api/payments');

      return fetch(url.toString(), requestOptions)
          .then(this.handleResponse)
          .then(this.handlePaymentsResponse)
          .catch((error) => {
             log_error('warn', 'BackendService', error);
             return Promise.reject('backend.fetch.failed.payments');
          });
  }

  handlePaymentsResponse(response){
    this.apiResponses['payments'] = response['hydra:member'];
    this.triggerCallbacks();
  }

  fetchReservations() {
      const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getCurrentUser().JWT }
      };

      const url = new URL(process.env.REACT_APP_API_ENTRYPOINT + '/../api/reservations');

      return fetch(url.toString(), requestOptions)
          .then(this.handleResponse)
          .then(this.handleReservationsResponse)
          .catch((error) => {
             log_error('warn', 'BackendService', error);
             if(error === 'Expired JWT Token'){
               auth.logout();
               auth.requireLogin();
             }
             return Promise.reject('backend.fetch.failed.reservations');
          });
  }

  handleReservationsResponse(response){
    this.apiResponses['reservations'] = response['hydra:member'];
    this.triggerCallbacks();
  }

  fetchProperty() {
      const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getCurrentUser().JWT }
      };

      const url = new URL(process.env.REACT_APP_API_ENTRYPOINT + '/../api/properties');

      return fetch(url.toString(), requestOptions)
          .then(this.handleResponse)
          .then(this.handlePropertyResponse)
          .catch((error) => {
             log_error('warn', 'BackendService', error);
             return Promise.reject('backend.fetch.failed.properties');
          });
  }

  handlePropertyResponse(response){
    this.apiResponses['properties'] = response['hydra:member'];
    this.triggerCallbacks();
  }

  fetchPropertyImage() {
      const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getCurrentUser().JWT }
      };

      const url = new URL(process.env.REACT_APP_API_ENTRYPOINT + '/../api/property_images');

      return fetch(url.toString(), requestOptions)
          .then(this.handleResponse)
          .then(this.handlePropertyImageResponse)
          .catch((error) => {
             log_error('warn', 'BackendService', error);
             return Promise.reject('backend.fetch.failed.property_images');
          });
  }

  handlePropertyImageResponse(response){
    this.apiResponses['propertyImages'] = response['hydra:member'];
    this.triggerCallbacks();
  }

  handleResponse(response) {
      return response.text().then(text => {
          const data = text && JSON.parse(text);
          if (!response.ok) {
              if ([401, 403].indexOf(response.status) !== -1) {
                  // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                  auth.logout();
              }

              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
          }

          return data;
      });
  }

}
