import React from 'react';
import AuthenticationService from './services/AuthenticationService';
import {withNamespaces} from 'react-i18next';
import BackendService from './services/BackendService';
import BottomNav from './elements/BottomNav';
import TopNav from './elements/TopNav';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Loading from './elements/Loading';
import {Helmet} from "react-helmet";

const auth = new AuthenticationService();

class Host extends React.Component {

  constructor({t, props}) {
    super(props);

    this.updateState = this.updateState.bind(this);

    this.backend = new BackendService();

    this.backend.registerCallback(this.updateState);

    this.state = {
      reservations: this.backend.get('reservations'),
      host: this.backend.get('host'),
    };

    this.t = t;

    auth.requireLogin();
  }

  updateState() {
    this.setState({
      reservations: this.backend.get('reservations'),
      host: this.backend.get('host'),
    });
  }

  render() {

    if (!this.state.reservations || !this.state.host) {
      return (
        <div>
          <Helmet defer={false}>
            <title>Gast App - Gastgeber</title>
          </Helmet>
          <TopNav isLoggedIn={false}/>
          <Loading/>
          <BottomNav/>
        </div>
      );
    }

    let reserv = this.state.reservations[0] || {};
    let host = this.state.host[0] || {};

    return (
      <div>
        <Helmet defer={false}>
          <title>Gast App - Gastgeber</title>
        </Helmet>
        <TopNav isLoggedIn={auth.isLoggedIn()}/>
        <div className="container p-0 overflow-hidden p-3 pb-5 mt-1">


          <div className="row mt-4 mb-3">
            <div className="col-12">
              <h2
                className="text-center text-darksecondary font-weight-bold">{this.t(
                'app.host.title')}</h2>
            </div>
          </div>


          <div className="row">
            <div className="col-12">

              <div className="row card alert-info mb-0 mb-md-4 p-4">
                <div className="col-12">
                  <span className="lead font-weight-bold">{this.t(
                    'app.host.reservation_no')}</span><br/>
                  <span className="small font-italic">{this.t(
                    'app.host.reservation_no_subline')}</span>
                </div>
                <div className="col-12 bg-light p-1 pt-2 pr-3 pl-2 mt-2">
                  <span
                    className="lead text-darksecondary text-monospace font-weight-bold">{reserv.externalReservationNo}</span>

                </div>
              </div>


              {!host.usesTeam &&
              <div className="row">

                <div className="col-12 col-md-6 mb-0 mb-md-4">
                  <div className="row">
                    <div
                      className="col-12 lead p-2 pl-3 bg-secondary text-light">
                      <FontAwesomeIcon icon={['fad', 'user']}/> {this.t(
                      'app.host.host.headline')}

                    </div>

                    <div
                      className="col-12 border-secondary border p-5 bg-light">

                      {host.hostSalutation}<br/>
                      {host.hostTitle}<br/>
                      {host.hostGivenname}&nbsp;{host.hostSurname}<br/>


                      {host.hostStreet}<br/>
                      {host.hostStreet2}<br/>

                      {host.hostPostalcode}&nbsp;{host.hostCity}<br/>
                      {host.hostCountry}

                    </div>
                  </div>
                </div>
                <div className="col-1 d-none d-md-block"></div>
                <div className="col-12 col-md-5">

                  <div className="row">
                    <div
                      className="col-12 lead bg-secondary text-light p-2 pl-3">
                      <FontAwesomeIcon icon={['fad', 'address-card']}/> {this.t(
                      'app.host.host.contact')}
                    </div>
                    <div
                      className="col-12 border-secondary border p-4 bg-light">


                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 font-weight-bold">
                            <FontAwesomeIcon
                              icon={['fad', 'envelope']}/> {this.t(
                            'app.host.host.email')}
                          </div>
                          <div className="col-6 text-right"><a
                            href={'mailto:' + host.hostEmail + '?subject=' +
                            this.t('app.host.reservation') + '%20' +
                            reserv.externalReservationNo}
                            target="_blank" rel="noopener noreferrer"
                            className="btn btn-info btn-sm d-inline-block">{this.t(
                            'app.host.sendMail')}</a>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-3"><a
                        href={'mailto:' + host.hostEmail + '?subject=' +
                        this.t('app.host.reservation') + '%20' +
                        reserv.externalReservationNo}
                        target="_blank" rel="noopener noreferrer"
                        className="text-darksecondary">{host.hostEmail}</a>
                      </div>

                      <div className="col-12 font-weight-bold"><FontAwesomeIcon
                        icon={['fad', 'mobile-alt']}/> {this.t(
                        'app.host.host.mobile')}</div>
                      <div className="col-12 mb-3"><a
                        href={'tel:' + host.hostMobile}
                        target="_blank"
                        rel="noopener noreferrer">{host.hostMobile}</a>
                      </div>

                      <div className="col-12 font-weight-bold"><FontAwesomeIcon
                        icon={['fad', 'phone']}/> {this.t(
                        'app.host.host.phone')}
                      </div>
                      <div className="col-12 mb-3"><a
                        href={'tel:' + host.hostPhone}
                        target="_blank"
                        rel="noopener noreferrer">{host.hostPhone}</a>
                      </div>

                      <div className="col-12 font-weight-bold"><FontAwesomeIcon
                        icon={['fad', 'phone']}/> {this.t(
                        'app.host.host.phone2')}</div>
                      <div className="col-12 mb-3"><a
                        href={'tel:' + host.hostPhone2}
                        target="_blank"
                        rel="noopener noreferrer">{host.hostPhone2}</a>
                      </div>

                      <div className="col-12 font-weight-bold"><FontAwesomeIcon
                        icon={['fad', 'print']}/> {this.t('app.host.host.fax')}
                      </div>
                      <div className="col-12">{host.hostFax}</div>

                    </div>
                  </div>
                </div>

              </div>
              }

              {host.usesTeam &&
              <div className="row">

                <div className="col-12 col-md-6 mb-0 mb-md-4">
                  <div className="row">
                    <div
                      className="col-12 lead p-2 pl-3 bg-secondary text-light">
                      <FontAwesomeIcon icon={['fad', 'user']}/> {this.t(
                      'app.host.team.headline')}
                    </div>

                    <div
                      className="col-12 border-secondary border p-5 bg-light">
                      {host.teamCompanyname}<br/>
                      {host.teamTeamname}<br/>
                      {host.teamStreet}<br/>
                      {host.teamPostalcode}&nbsp;{host.teamCity}<br/>
                      {host.teamCountry}
                    </div>
                  </div>
                </div>
                <div className="col-1 d-none d-md-block"></div>
                <div className="col-12 col-md-5">

                  <div className="row">
                    <div
                      className="col-12 lead bg-secondary text-light p-2 pl-3">
                      <FontAwesomeIcon icon={['fad', 'address-card']}/> {this.t(
                      'app.host.team.contact')}
                    </div>
                    <div
                      className="col-12 border-secondary border p-4 bg-light">


                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 font-weight-bold">
                            <FontAwesomeIcon
                              icon={['fad', 'envelope']}/> {this.t(
                            'app.host.team.email')}
                          </div>
                          <div className="col-6 text-right"><a
                            href={'mailto:' + host.teamEmail + '?subject=' +
                            this.t('app.host.reservation') + '%20' +
                            reserv.externalReservationNo}
                            target="_blank" rel="noopener noreferrer"
                            className="btn btn-info btn-sm d-inline-block">{this.t(
                            'app.host.sendMail')}</a>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-3"><a
                        href={'mailto:' + host.teamEmail + '?subject=' +
                        this.t('app.host.reservation') + '%20' +
                        reserv.externalReservationNo}
                        target="_blank" rel="noopener noreferrer"
                        className="text-darksecondary">{host.teamEmail}</a>
                      </div>

                      <div className="col-12 font-weight-bold"><FontAwesomeIcon
                        icon={['fad', 'phone']}/> {this.t(
                        'app.host.team.phone')}
                      </div>
                      <div className="col-12 mb-3"><a
                        href={'tel:' + host.teamPhone}
                        target="_blank"
                        rel="noopener noreferrer">{host.teamPhone}</a>
                      </div>

                      <div className="col-12 font-weight-bold"><FontAwesomeIcon
                        icon={['fad', 'print']}/> {this.t('app.host.team.fax')}
                      </div>
                      <div className="col-12">{host.teamFax}</div>

                    </div>
                  </div>
                </div>

              </div>
              }

            </div>
          </div>


        </div>
        <BottomNav/>
      </div>
    );
  }
}

export default withNamespaces()(Host);

