import React from 'react';
import {withNamespaces} from 'react-i18next';
import BackendService from './../services/BackendService';
import AuthenticationService from './../services/AuthenticationService';
import EditAddonsUrlService from '../services/EditAddonsUrlService';

const auth = new AuthenticationService();

class EditAddons extends React.Component {

  constructor({t, props}) {
    super(props);

    this.updateState = this.updateState.bind(this);

    this.backend = new BackendService();

    this.backend.registerCallback(this.updateState);

    this.state = {
      reservations: this.backend.get('reservations')
    };

    this.t = t;
  }

  updateState() {
    this.setState({
      reservations: this.backend.get('reservations')
    });
  }

  render() {

    if (!this.state.reservations || !auth.isLoggedIn()) {
      return null;
    }

    let reserv = this.state.reservations[0] || {};

    if (!reserv.editAddonsUrl) {
      return null;
    }

    if (auth.isImpersonation()) {
      return (
        <li className="nav-item col-12">
          <span className="nav-link text-dark">{this.t('app.menu.editAddons')}</span>
        </li>
      );
    }

    return (
      <li className="nav-item col-12">
        <a className="nav-link" href={EditAddonsUrlService.getHref(reserv.editAddonsUrl)}
           target={'_blank'} rel="noopener noreferrer">{this.t('app.menu.editAddons')}</a>
      </li>

    );
  }
}

export default withNamespaces()(EditAddons);
