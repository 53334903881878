import React from 'react';
import AuthenticationService from './services/AuthenticationService';
import {withNamespaces} from 'react-i18next';
import TopNav from './elements/TopNav';
import BottomNav from './elements/BottomNav';
import BackendService from './services/BackendService';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from './elements/Loading';
import {Helmet} from "react-helmet";
import createDOMPurify from 'dompurify'

const auth = new AuthenticationService();

const DOMPurify = createDOMPurify(window);

class Property extends React.Component {

  constructor({t, props}) {
    super(props);

    this.updateState = this.updateState.bind(this);

    this.backend = new BackendService();

    this.backend.registerCallback(this.updateState);

    this.state = {
      properties: this.backend.get('properties'),
      propertyImages: this.backend.get('propertyImages'),
      propertyEquipmentGrid: this.backend.get('propertyEquipmentGrid'),
    };

    this.t = t;

    auth.requireLogin();
  }

  updateState() {
    this.setState({
      properties: this.backend.get('properties'),
      propertyImages: this.backend.get('propertyImages'),
      propertyEquipmentGrid: this.backend.get('propertyEquipmentGrid'),
    });
  }

  componentDidMount() {
    $('.carousel').carousel();

  }

  componentDidUpdate() {
    $('.carousel').carousel();
  }

  render() {

    if (!this.state.properties || !this.state.propertyImages || !this.state.propertyEquipmentGrid) {
      return (
        <div>
          <Helmet defer={false}>
            <title>Gast App - Objekt</title>
          </Helmet>
          <TopNav isLoggedIn={false}/>
            <Loading />
          <BottomNav/>
        </div>
      );
    }

    //console.log(this.state);

    let property = this.state.properties[0] || {};
    let images = this.state.propertyImages || {};
    let equip = this.state.propertyEquipmentGrid || {};

    const hasImages = images.length > 0;

    return (
      <div className="d-flex flex-column">
        <Helmet defer={false}>
          <title>Gast App - Objekt</title>
        </Helmet>
        <TopNav isLoggedIn={auth.isLoggedIn()}/>
        <div className={'container p-0 overflow-hidden pb-5 mt-1'}>
          <div className={'row'}>


            {hasImages &&
            <div id="objCarousel"
                 className="col-12 p-0 mb-4 objectcarousel carousel carousel-fade slide align-middle"
                 data-interval="5000" data-ride="carousel">

              <div className="carousel-inner mb-1">
                <ol className="carousel-indicators">
                  <li data-target="#objCarousel" data-slide-to="0"
                      className="active"></li>
                  {images.map((imageSet, i) => {
                    if (i < 1) {
                      return ('');
                    }
                    return (
                      <li key={i} data-target="#objCarousel"
                          data-slide-to={i}></li>
                    );
                  })
                  }
                </ol>

                {images.map((imageSet, i) => {

                  let active = i === 0 ? 'active' : '';
                  return (
                    <div key={i} className={'carousel-item ' + active}><img
                      className="d-block w-100 h-100"
                      style={{objectFit: 'cover'}}
                      src={imageSet.url} border="0" alt=""/></div>
                  );
                })
                }


                <a className="carousel-control-prev" href="#objCarousel"
                   role="button" data-slide="prev">
                  <span className="text-light nav_icon" aria-hidden="true">
                    <FontAwesomeIcon icon={['fad', 'chevron-left']}
                                     transform={"grow-2"}/>
                  </span>
                  <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#objCarousel"
                   role="button" data-slide="next">
                  <span className="nav_icon text-light" aria-hidden="true">
                    <FontAwesomeIcon icon={['fad', 'chevron-right']}
                                     transform={"grow-2"}/>
                  </span>
                  <span className="sr-only">Next</span>
                </a>

              </div>

              <div className="row pl-sm-3 pr-sm-3">


                {images.map((imageSet, i) => {

                  if (i > 3) {
                    return ('');
                  }

                  let extra = '';
                  let rest = imageSet.length - 4;

                  if (i === 3 && imageSet.length > 4) {
                    extra = <div
                      className="more_images text-light d-flex align-items-center justify-content-center"> + {rest}</div>;

                  }

                  return (
                    <div key={i} className="col-3 p-0 m-0 pr-1"
                         data-target="#objCarousel" data-slide-to={i}><img
                      className="d-block w-100 h-100 quick_nav_img_item"
                      style={{objectFit: 'cover'}}
                      src={imageSet.url} border="0" alt=""/>{extra}</div>
                  );
                })
                }


              </div>
            </div>
            }


            <div className={'col-12 mt-4'}>
              <div className="col-12 mb-4">
                <h2
                  className="text-center text-darksecondary font-weight-bold">{this.t('app.property.headline')}</h2>
              </div>

              <div className={'row'}>
              <div className="col-12 col-sm-8">
                <div
                  className="col-12 object-title font-weight-bold">{property.name}
                </div>
                <div
                  className="col-12">{property.street}
                </div>
                <div
                  className="col-12">{property.postalcode} {property.city}
                </div>
                <div className="col-12">{property.country}
                </div>


              </div>

              {property.latitude && property.longitude &&
              <div className="col-12 col-sm-4">

                <a className="btn btn-info text-light ml-3"
                   href={"https://www.google.com/maps/dir/?api=1&destination="+property.latitude+','+property.longitude}
                   target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fad', 'map-marked-alt']} /> {this.t('app.property.route_button')}</a>
              </div>
              }
              </div>

              <div className="col-12 mt-4">
                <h5
                  className="text-darksecondary font-weight-bold">{this.t('app.property.properties')}</h5>
              </div>

              <div className="col-12 row">
                <div className="col-6 col-sm-6 col-md-4 text-truncate"><span
                  className="font-weight-bold lead mr-2">{property.bedrooms}</span>{this.t('app.property.bedrooms')}
                </div>
                <div className="col-6 col-sm-6 col-md-4 text-truncate"><span
                  className="font-weight-bold lead mr-2">{property.livingrooms}</span>{this.t('app.property.livingrooms')}
                </div>
                <div className="col-6 col-sm-6 col-md-4 text-truncate"><span
                  className="font-weight-bold lead mr-2">{property.living_and_bed_comboroom}</span>{this.t('app.property.living_and_bed_comboroom')}
                </div>
                <div className="col-6 col-sm-6 col-md-4 text-truncate"><span
                  className="font-weight-bold lead mr-2">{property.bathrooms}</span>{this.t('app.property.bathrooms')}
                </div>
                <div className="col-6 col-sm-6 col-md-4 text-truncate"><span
                  className="font-weight-bold lead mr-2">{property.guesttoilets}</span>{this.t('app.property.guesttoilets')}
                </div>
                <div className="col-6 col-sm-6 col-md-4 text-truncate"><span
                  className="font-weight-bold lead mr-2">{property.kitchenrooms}</span>{this.t('app.property.kitchenrooms')}
                </div>
              </div>

              <div className={'row col-12 mt-3 mb-3'}>

                {equip.map((eqrow, i) => {

                  return (
                    <div key={i} className={'row col-12 col-sm-6 col-md-4 container d-flex'}>

                          <div className={'col-1 text-nowrap'}>
                          {!eqrow.isAvailable &&
                          <FontAwesomeIcon className={'text-muted'} icon={['fad', 'times-circle']} />
                          }
                          {eqrow.isAvailable &&
                          <FontAwesomeIcon className={'text-success'} icon={['fad', 'check-circle']} />
                          }
                          </div>
                          <div className={'col'}>
                          {eqrow.name}
                          </div>

                    </div>
                  );
                })
                }

              </div>

              <div className="col-12 mt-4 mb-4 textwrap" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(property.additional_properties, { USE_PROFILES: { html: true } }) }} />

              <div className="col-12 mt-4 textwrap" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(property.description, { USE_PROFILES: { html: true } }) }} />

            </div>

          </div>


        </div>

        <BottomNav/>
      </div>
    );
  }
}

export default withNamespaces()(Property);
