import React from 'react';
import AuthenticationService from './services/AuthenticationService';
import {withNamespaces} from 'react-i18next';
import ImprintNotice from './elements/ImprintNotice';
import TopNav from './elements/TopNav';
import {Helmet} from "react-helmet";

const auth = new AuthenticationService();

class Logout extends React.Component {

  constructor({t, props}) {
    super(props);
    this.t = t;
    auth.logout();
  }

  render() {
    return (
      <div className="d-flex flex-column">
        <Helmet defer={false}>
          <title>Gast App - Logout</title>
        </Helmet>
        <TopNav />
        <div className="mt-3 p-3 bg-light container">
          <div className="row pt-3">
            <div className="col-12 text-center text-darksecondary"><b>{this.t('logout.notice')}</b></div>
          </div>
          <div className="row">
            <div className="col-12 pt-5 text-center">
              <a href="login" className="btn btn-secondary text-white">{ this.t('logout.login_link')}</a>
            </div>
          </div>
        </div>
        <ImprintNotice />
      </div>
    );
  }
}

export default withNamespaces()(Logout);
