import React from 'react';
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WeatherWidget = ({ t, dataset, indexA = 0, indexB = 1, showHeadline = true }) =>  {

  const icons = {
        '01d': {'icon': 'sun', 'style':  {'--fa-primary-color': 'gold', '--fa-secondary-color': 'orange'}},
        '02d': {'icon': 'cloud-sun', 'style':  {'--fa-primary-color': 'grey', '--fa-secondary-color': 'orange'}},
        '03d': {'icon': 'cloud', 'style':  {'--fa-primary-color': 'grey', '--fa-secondary-color': '#CCC'}},
        '04d': {'icon': 'clouds', 'style':  {'--fa-primary-color': 'grey', '--fa-secondary-color': '#CCC'}},
        '09d': {'icon': 'cloud-drizzle', 'style':  {'--fa-primary-color': '#CCC', '--fa-secondary-color': 'blue'}},
        '10d': {'icon': 'cloud-showers-heavy', 'style':  {'--fa-primary-color': 'grey', '--fa-secondary-color': 'blue'}},
        '11d': {'icon': 'thunderstorm', 'style':  {'--fa-primary-color': '#404040', '--fa-secondary-color': 'orange'}},
        '13d': {'icon': 'snowflake', 'style':  {'--fa-primary-color': '#DDD', '--fa-secondary-color': '#DDD'}},
        '50d': {'icon': 'fog', 'style':  {'--fa-primary-color': 'grey', '--fa-secondary-color': 'grey'}},
        '01n': {'icon': 'moon', 'style':  {'--fa-primary-color': '#CCC', '--fa-secondary-color': '#CCC'}},
        '02n': {'icon': 'cloud-moon', 'style':  {'--fa-primary-color': '#CCC', '--fa-secondary-color': 'grey'}},
        '03n': {'icon': 'cloud', 'style':  {'--fa-primary-color': 'grey', '--fa-secondary-color': 'grey'}},
        '04n': {'icon': 'clouds', 'style':  {'--fa-primary-color': '#AAA', '--fa-secondary-color': '#BBB'}},
        '09n': {'icon': 'cloud-drizzle', 'style':  {'--fa-primary-color': 'grey', '--fa-secondary-color': 'blue'}},
        '10n': {'icon': 'cloud-showers-heavy', 'style':  {'--fa-primary-color': '#404040', '--fa-secondary-color': 'blue'}},
        '11n': {'icon': 'thunderstorm', 'style':  {'--fa-primary-color': '#404040', '--fa-secondary-color': 'orange'}},
        '13n': {'icon': 'snowflake', 'style':  {'--fa-primary-color': '#DDD', '--fa-secondary-color': '#DDD'}},
        '50n': {'icon': 'fog', 'style':  {'--fa-primary-color': 'grey', '--fa-secondary-color': 'grey'}}
  };

  return (

        <div className="container pt-3 pb-3">
          <div className={"row"}>
            <a href="weather" className="btn col-12">
                <div className="col-12">
                  {showHeadline &&
                  <h4 className="text-darksecondary text-center"><b>{t(
                    'app.index.weather.headline')}</b>
                  </h4>
                  }
                    <div className="row text-left">
                        <div className="col-6 p-1">
                            <div className="bg-light m-1 p-2 row">
                                <div className="col-12 text-center small mb-2">
                                  {showHeadline &&
                                  <span>{ t('app.index.weather.today') }, </span>
                                  }
                                    { (new Date(dataset.list[indexA].dt * 1000)).toLocaleDateString() }</div>
                                <div className="col-4 p-4">
                                  <FontAwesomeIcon icon={['fad', icons[dataset.list[indexA].weather[0].icon].icon]} transform={'grow-25'} style={icons[dataset.list[indexA].weather[0].icon].style} />
                                </div>
                                <div className="col-8 text-left">
                                    <p className="small mb-0">{ dataset.list[indexA].weather[0].description }</p>
                                    <div className="row">
                                        <div className="col-6 lead"><b>{ parseFloat(dataset.list[indexA].temp.max).toFixed(1).toLocaleString() }&deg;</b></div>
                                        <div className="col-6 small pt-2">
                                          { parseFloat(dataset.list[indexA].temp.min).toFixed(1).toLocaleString() }&deg;
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 small">{ t('app.index.weather.max') }</div>
                                        <div className="col-6 small">{ t('app.index.weather.min') }</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 p-1">
                            <div className="bg-light m-1 p-2 row">
                                <div className="col-12 text-center small mb-2">
                                  {showHeadline &&
                                  <span>{t('app.index.weather.tomorrow')}, </span>
                                  }
                                  { (new Date(dataset.list[indexB].dt * 1000)).toLocaleDateString() }</div>
                                <div className="col-4 p-4">
                                  <FontAwesomeIcon icon={['fad', icons[dataset.list[indexB].weather[0].icon].icon]} transform={'grow-25'} style={icons[dataset.list[indexB].weather[0].icon].style} />
                                </div>
                                <div className="col-8 text-left">
                                    <p className="small mb-0">{ dataset.list[indexB].weather[0].description }</p>
                                    <div className="row">
                                        <div className="col-6 lead"><b>{ parseFloat(dataset.list[indexB].temp.max).toFixed(1).toLocaleString() }&deg;</b></div>
                                        <div className="col-6 small pt-2">
                                          { parseFloat(dataset.list[indexB].temp.min).toFixed(1).toLocaleString() }&deg;
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 small">{ t('app.index.weather.max') }</div>
                                        <div className="col-6 small">{ t('app.index.weather.min') }</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
          </div>
        </div>

      );
};

export default withNamespaces()(WeatherWidget);
