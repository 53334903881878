import React from 'react';
import i18n from './../services/i18n';
import './../css/LangSwitch.css';

const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
};

const LangSwitch = () => (
  <div className={'row'}>
    <div className={'col-12 mt-2 ml-2'}>
      <div onClick={() => changeLanguage('de')}
           className="flag-icon flag-icon-de mr-2"></div>
      <div onClick={() => changeLanguage('en')}
           className="flag-icon flag-icon-us mr-2"></div>
    </div>
  </div>
);

export default LangSwitch;
