import i18n from './i18n';

class EditAddonsUrlService {

  getHref(editUrl) {
    let url;

    if (editUrl.startsWith('http://') || editUrl.startsWith('https://')) {
      url = new URL(editUrl);
    } else {
      url = new URL('https://' + editUrl);
    }

    url.searchParams.set('withHeader', '0');
    url.searchParams.set('lang', i18n.language);

    return url.toString();
  }
}

export default new EditAddonsUrlService();
