import React from 'react';
import {withNamespaces} from 'react-i18next';
import BackendService from './../services/BackendService';
import AuthenticationService from './../services/AuthenticationService';

const auth = new AuthenticationService();

class GuestInfoMenuItem extends React.Component {

  constructor({t, props}) {
    super(props);

    this.updateState = this.updateState.bind(this);

    this.backend = new BackendService();

    this.backend.registerCallback(this.updateState);

    this.state = {
      guestinfo: this.backend.get('guestInfo')
    };

    this.t = t;
  }

  updateState() {
    this.setState({
      guestinfo: this.backend.get('guestInfo')
    });
  }

  render() {

    if (!this.state.guestinfo || !auth.isLoggedIn()) {
      return null;
    }

    if(this.state.guestinfo.length < 1){
      return null;
    }

    return (
      <li className="nav-item col-12">
        <a className="nav-link" href={'guestinfo'}>{this.t('app.menu.guestinfo')}</a>
      </li>

    );
  }
}

export default withNamespaces()(GuestInfoMenuItem);
