import React from 'react';
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Loading = ({ t }) => {
      return (

        <div className={'container'}>
          <div className={'row loadinganimation'}>
            <div className={'col-12 d-flex justify-content-center pt-4 mt-4 pb-4'}>
              <FontAwesomeIcon icon={['fad', 'spinner']} spin size={'3x'} />
            </div>
          </div>
        </div>

      );
};

export default withNamespaces()(Loading);
