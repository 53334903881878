import React from 'react';
import {withNamespaces} from 'react-i18next';
import BackendService from './../services/BackendService';
import AuthenticationService from './../services/AuthenticationService';

const auth = new AuthenticationService();

class CustomLinks extends React.Component {

  constructor({t, props, itemNo}) {
    super(props);

    this.updateState = this.updateState.bind(this);

    this.backend = new BackendService();

    this.backend.registerCallback(this.updateState);

    this.state = {
      customLinks: this.backend.get('customLinks'),
    };

    this.itemNo = itemNo;

    this.t = t;
  }

  updateState() {
    this.setState({
      customLinks: this.backend.get('customLinks'),
    });
  }

  render() {

    if (!this.state.customLinks || !auth.isLoggedIn()) {
      return null;
    }

    let linklist = this.state.customLinks || [];

    if (!linklist.length > 0 || !linklist[this.itemNo]) {
      return null;
    }

    let link = linklist[this.itemNo];

    return (
      <li className="nav-item col-12" key={this.itemNo}>
        <a className="nav-link" href={link.url}
           target={'_blank'}
           rel="noopener noreferrer">{link.title}</a>
      </li>
    );

  }
}

export default withNamespaces()(CustomLinks);
