import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, combineReducers, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {
  ConnectedRouter,
  connectRouter,
  routerMiddleware,
} from 'connected-react-router';

import './css/bootstrap.css';

import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import * as serviceWorker from './serviceWorker';
import './services/i18n';
import 'flag-icon-css/css/flag-icon.min.css';

import './css/base.css';

import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faSignInAlt,
  faSignOutAlt,
  faUserFriends,
  faHome,
  faBookOpen,
  faAddressCard,
  faSadTear,
  faSun,
  faCloudSun,
  faCloud,
  faClouds,
  faCloudDrizzle,
  faCloudShowersHeavy,
  faThunderstorm,
  faSnowflake,
  faFog,
  faMoon,
  faCloudMoon,
  faKey,
  faCheckCircle,
  faUser,
  faEnvelope,
  faMobileAlt,
  faPhone,
  faPrint,
  faChevronLeft,
  faChevronRight,
  faMapMarkedAlt,
  faTimesCircle,
  faSpinner,
  faDownload,
  faCar,
  faWifi,
  faBook,
  faGavel,
  faCopy,
  faGift,
  faShieldVirus,
  faFilePdf,
  faLockOpen,
  faLock
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faArrowRight,
} from '@fortawesome/pro-solid-svg-icons';

import Login from './Login';
import LandingPage from './LandingPage';
import Overview from './Overview';
import Logout from './Logout';
import Sorry from './Sorry';
import Imprint from './Imprint';
import Reservation from './Reservation';
import Property from './Property';
import Host from './Host';
import Weather from './Weather';
import GuestInfo from './GuestInfo';

import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import CustomStyleLoader from './elements/CustomStyleLoader';
import PaymentOverview from './PaymentOverview';
import Dataprotection from './Dataprotection';

if(window.location.pathname.indexOf('localhost')===false) {
  Sentry.init({
    dsn: 'https://1d3f0a177742407fba0c9011c377deb1@sentry.secra.dev/7',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ["localhost", "guest-app.secra.de", /^\//],
      }),
    ],
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({eventId: event.event_id});
      }
      return event;
    },
    tracesSampleRate: 1.0,
  });
}

library.add(
  faArrowRight,
  faSignInAlt,
  faSignOutAlt,
  faUserFriends,
  faHome,
  faBookOpen,
  faAddressCard,
  faSadTear,
  faSun,
  faCloudSun,
  faCloud,
  faClouds,
  faCloudDrizzle,
  faCloudShowersHeavy,
  faThunderstorm,
  faSnowflake,
  faFog,
  faMoon,
  faCloudMoon,
  faKey,
  faCheckCircle,
  faUser,
  faEnvelope,
  faMobileAlt,
  faPhone,
  faPrint,
  faChevronLeft,
  faChevronRight,
  faMapMarkedAlt,
  faTimesCircle,
  faSpinner,
  faDownload,
  faCar,
  faWifi,
  faBook,
  faGavel,
  faCopy,
  faGift,
  faShieldVirus,
  faFilePdf,
  faLock,
  faLockOpen
);

const history = createBrowserHistory();
const store = createStore(
  combineReducers({
    router: connectRouter(history),
  }),
  applyMiddleware(routerMiddleware(history)),
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={LandingPage} strict={true} exact={true}/>
        <Route path="/login" component={Login} strict={true} exact={true}/>
        <Route path="/logout" component={Logout} strict={true} exact={true}/>
        <Route path="/overview" component={Overview} strict={true}
               exact={true}/>
        <Route path="/imprint" component={Imprint} strict={true}
               exact={true}/>
        <Route path="/dataprotection" component={Dataprotection} strict={true}
               exact={true}/>
        <Route path="/reservation" component={Reservation} strict={true}
               exact={true}/>
        <Route path="/property" component={Property} strict={true}
               exact={true}/>
        <Route path="/host" component={Host} strict={true} exact={true}/>
        <Route path="/weather" component={Weather} strict={true} exact={true}/>
        <Route path="/paymentstatus" component={PaymentOverview} strict={true} exact={true}/>
        <Route path="/guestinfo" component={GuestInfo} strict={true} exact={true}/>
        {/* Add your routes here */}
        <Route component={Sorry}/>
      </Switch>
    </ConnectedRouter>
    <CustomStyleLoader/>
  </Provider>
  ,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
