import React from 'react';
import AuthenticationService from './services/AuthenticationService';
import {withNamespaces} from 'react-i18next';
import TopNav from './elements/TopNav';
import BottomNav from './elements/BottomNav';
import ImprintNotice from './elements/ImprintNotice';
import WeatherWidget from './elements/WeatherWidget';
import BackendService from './services/BackendService';
import Loading from './elements/Loading';
import {Helmet} from "react-helmet";

const auth = new AuthenticationService();

class Weather extends React.Component {

  constructor({t, props}) {
    super(props);

    this.updateState = this.updateState.bind(this);

    this.backend = new BackendService();

    this.backend.registerCallback(this.updateState);

    this.state = {
      properties: this.backend.get('properties'),
    };

    this.t = t;

    auth.requireLogin();
  }

  updateState() {
    this.setState({
      properties: this.backend.get('properties'),
    });
  }

  render() {

    if (!this.state.properties) {
      return (
        <div>
          <Helmet defer={false}>
            <title>Gast App - Wetter</title>
          </Helmet>
          <TopNav isLoggedIn={false}/>
            <Loading />
          <BottomNav/>
        </div>
      );
    }

    let property = this.state.properties[0] || {};

    return (
      <div className="d-flex flex-column">
        <Helmet defer={false}>
          <title>Gast App - Wetter</title>
        </Helmet>
        <TopNav isLoggedIn={auth.isLoggedIn()}/>
        <div className={'container'}>
          <div className={'row'}>
            <div className="col-12 mt-4 mb-3">
              <h2
                className="text-center text-darksecondary font-weight-bold">{this.t(
                'app.weather.title')}</h2>
            </div>
            <div className={'col-12 p-0 m-0'}>
              <div className={'row'}>
                <div className={'bg-lighter col-12 col-md-6 mb-1 p-0'}>
                  <WeatherWidget
                    dataset={property.weather} indexA={0} indexB={1}
                    showHeadline={false}/></div>
                <div className={'bg-lighter col-12 col-md-6 mb-1 p-0'}>
                  <WeatherWidget
                    dataset={property.weather} indexA={2} indexB={3}
                    showHeadline={false}/></div>
                <div className={'bg-lighter col-12 col-md-6 mb-1 p-0'}>
                  <WeatherWidget
                    dataset={property.weather} indexA={4} indexB={5}
                    showHeadline={false}/></div>

              </div>
            </div>
          </div>
        </div>
        {!auth.isLoggedIn() &&
        <ImprintNotice/>
        }
        {auth.isLoggedIn() &&
        <BottomNav/>
        }
      </div>
    );
  }
}

export default withNamespaces()(Weather);
