import React from 'react';
import LangSwitch from './LangSwitch';
import { withNamespaces } from 'react-i18next';

class ImprintNotice extends React.Component {

  constructor({t, props}) {
    super(props);
    this.t = t;
  }

  render() {
    return (

        <div className="container mt-1 p-3">
          <div className="row">
          <div className="col-6 text-left"><small>&copy; {(new Date()).getFullYear()}&nbsp;
          { this.t('login.companyname')}<br/>
            <a href="imprint">{this.t(
              'login.imprint_link_description')}</a>&nbsp;|&nbsp;<a href="dataprotection">{this.t(
              'login.dataprotection_link_description')}</a></small></div>
          <div className="col-6 text-right"><LangSwitch /></div>
            </div>
        </div>

    );
  }
}

export default withNamespaces()(ImprintNotice);
