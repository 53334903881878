import React from 'react'
import usePWA from 'react-pwa-install-prompt'
import {withNamespaces} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const PWAInstallPopup = ({t}) => {
  const { isStandalone, isInstallPromptSupported, promptInstall } = usePWA()


  const onClickInstall = async () => {
    const didInstall = await promptInstall()
    if (didInstall) {
      // User accepted PWA install
    }
  }

const renderInstallButton = () => {
    if (isInstallPromptSupported && !isStandalone)

      return (
        <div className={'row'}>
          <button onClick={onClickInstall} id={'installLink'} className={"col-12 m-0 p-3 pt-4 alert alert-success"}>
            <FontAwesomeIcon icon={['fad', 'download']} /> {t('app.install.notification')}
          </button>
        </div>
      )
    return null
  }

  //console.log('isInstallPromptSupported', isInstallPromptSupported ? 'true' : 'false');
  //console.log('isStandalone', isStandalone ? 'true' : 'false');

  return (<div>
    {renderInstallButton()}
  </div>)
}

export default withNamespaces()(PWAInstallPopup);
