import React from 'react';
import log_error from './ErrorLogger';

export default class AuthenticationService extends React.Component {

  constructor(props) {
    super(props);

    this.currentUser = this.loadCurrentUser();

    this.handleResponse = this.handleResponse.bind(this);
    this.handlePersist = this.handlePersist.bind(this);
    this.logout = this.logout.bind(this);
    this.login = this.login.bind(this);
  }

  getJWTPayload() {
    if(this.isLoggedIn()) {
      let base64Url = this.currentUser.JWT.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    } else {
      return false;
    }
  };

  isImpersonation() {
    let payload = this.getJWTPayload();
    if(payload===false){
      return false;
    }
    if(payload['isImpersonation'] && payload['isImpersonation']===true){
      return true;
    }
    return false;
  }

  requireLogin(){
    if(!this.isLoggedIn()){
      window.location.href = '/login';
    }
  }

  isLoggedIn(){
    return this.currentUser && this.currentUser.JWT && this.currentUser.JWT !== null;
  }

  loadCurrentUser(){
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  saveCurrentUser(){
    return localStorage.setItem('currentUser', JSON.stringify(this.getCurrentUser()));
  }

  setCurrentUser(data){
    this.currentUser = data;
    this.saveCurrentUser();
    return this.currentUser;
  }

  getCurrentUser(){
    return this.currentUser;
  }

  login(username, password) {
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, password })
      };

      const url = new URL(process.env.REACT_APP_API_ENTRYPOINT + '/../authentication_token');

      return fetch(url.toString(), requestOptions)
          .then(this.handleResponse)
          .then(this.handlePersist)
          .catch((error) => {
             log_error('warn', 'AuthenticationService', error);
             return Promise.reject('login.invalid');
          });
  }

  logout() {
    this.setCurrentUser({});

    try {
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      });
    } catch (e) {
      // well... we can not do something about it
    }
  }

  handlePersist(response){
    this.setCurrentUser({ JWT: response.token });
  }

  handleResponse(response) {
      const that = this;
      return response.text().then(text => {
          const data = text && JSON.parse(text);
          if (!response.ok) {
              if ([401, 403].indexOf(response.status) !== -1) {
                  // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                  that.logout();
                  window.location.href = '/login';
              }

              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
          }

          return data;
      });
  }

}
