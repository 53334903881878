import React from 'react';
import {withNamespaces} from 'react-i18next';
import BackendService from './../services/BackendService';
import AuthenticationService from './../services/AuthenticationService';

const auth = new AuthenticationService();

class RegisterGuests extends React.Component {

  constructor({t, props}) {
    super(props);

    this.updateState = this.updateState.bind(this);

    this.backend = new BackendService();

    this.backend.registerCallback(this.updateState);

    this.state = {
      reservations: this.backend.get('reservations')
    };

    this.t = t;
  }

  updateState() {
    this.setState({
      reservations: this.backend.get('reservations')
    });
  }

  render() {

    if (!this.state.reservations || !auth.isLoggedIn()) {
      return null;
    }

    let reserv = this.state.reservations[0] || {};

    if(!reserv.guest_registration_url){
      return null;
    }

    if(auth.isImpersonation()){
      return (
        <li className="nav-item col-12">
          <span className="nav-link text-dark">{this.t('app.menu.guesttax')}</span>
        </li>
      );
    }

    return (
      <li className="nav-item col-12">
        <a className="nav-link" href={ reserv.guest_registration_url }
           target={'_blank'} rel="noopener noreferrer">{this.t('app.menu.guesttax')}</a>
      </li>

    );
  }
}

export default withNamespaces()(RegisterGuests);
