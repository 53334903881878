import React, {Fragment} from 'react';
import AuthenticationService from './services/AuthenticationService';
import {withNamespaces} from 'react-i18next';
import BackendService from './services/BackendService';
import BottomNav from './elements/BottomNav';
import TopNav from './elements/TopNav';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Loading from './elements/Loading';
import {Helmet} from 'react-helmet';
import KeyValueColumns from './elements/KeyValueColumns';
import {localeDate} from "./services/Utils";

const auth = new AuthenticationService();

class PaymentOverview extends React.Component {

  constructor({t, props}) {
    super(props);

    this.updateState = this.updateState.bind(this);

    this.backend = new BackendService();

    this.backend.registerCallback(this.updateState);

    this.state = {
      reservations: this.backend.get('reservations'),
      reservation_attached_services: this.backend.get(
        'reservationAttachedServices'),
      payments: this.backend.get('payments'),
      services: this.backend.get('reservationAttachedServices'),
      servicesSummary: this.backend.get('reservationAttachedServicesSummary'),
      host: this.backend.get('host')
    };

    this.t = t;

    auth.requireLogin();
  }

  updateState() {
    this.setState({
      reservations: this.backend.get('reservations'),
      reservation_attached_services: this.backend.get(
        'reservationAttachedServices'),
      payments: this.backend.get('payments'),
      services: this.backend.get('reservationAttachedServices'),
      servicesSummary: this.backend.get('reservationAttachedServicesSummary'),
      host: this.backend.get('host')
    });
  }

  render() {

    if (!this.state.reservations || !this.state.reservation_attached_services || !this.state.payments || !this.state.host) {
      return (
        <div>
          <Helmet defer={false}>
            <title>Gast App - Zahlungsstatus</title>
          </Helmet>
          <TopNav isLoggedIn={false}/>
          <Loading/>
          <BottomNav/>
        </div>
      );
    }

    let reserv = this.state.reservations[0] || {};
    let paymentstatus = this.state.payments[0] || null;
    let reservationAttachedServicesSummary = this.state.servicesSummary || {};
    let deposit = this.state.services.find((service) => service.name === 'Kaution') || {};
    let host = this.state.host[0] || {};

    if( paymentstatus === null){ // no payment info, link shouldn't be visible, but catch this anyway
      return (
        <div>
          <Helmet defer={false}>
            <title>Gast App - Zahlungsstatus</title>
          </Helmet>
          <TopNav isLoggedIn={false}/>
          <Loading/>
          <BottomNav/>
        </div>
      );
    }

    return (
      <div>
        <Helmet defer={false}>
          <title>Gast App - Zahlungsstatus</title>
        </Helmet>
        <TopNav isLoggedIn={auth.isLoggedIn()}/>
        <div className="container p-0 overflow-hidden p-3 pb-5 mt-1">

          <div className="row">
            <div className="col-12 mt-4 mb-3">
              <h2
                className="text-center text-darksecondary font-weight-bold">{this.t(
                'app.paymentstatus.title')}</h2>
            </div>
          </div>

          <div className="row mb-4">
            <KeyValueColumns
              label={this.t('app.paymentstatus.bookingno')}
              labelClassName="font-weight-bolder"
              value={reserv.externalReservationNo}
              valueClassName="font-weight-bolder"
            />

            <KeyValueColumns
              label={this.t('app.paymentstatus.reserved_at')}
              value={localeDate(reserv.date_of_reservation)}
            />

            <div className="col-12 mt-4"></div>

            <KeyValueColumns
              label={this.t('app.paymentstatus.arrival')}
              labelClassName="font-weight-bold"
              value={localeDate(reserv.arrival)}
              valueClassName="font-weight-bold"
            />

            <KeyValueColumns
              label={this.t('app.paymentstatus.departure')}
              labelClassName="font-weight-bold"
              value={localeDate(reserv.departure)}
              valueClassName="font-weight-bold"
            />

            <div className="col-12 mt-4"></div>

            <KeyValueColumns
              label={this.t('app.paymentstatus.totalamount')}
              value={<u>{ paymentstatus.totalAmount.toLocaleString(undefined, {style: "currency", currency: "EUR", minimumFractionDigits: 2}) }</u>}
              valueClassName='font-weight-bold lead'
            />

            { paymentstatus.depositAmount > 0 &&
            <KeyValueColumns
              label={this.t('app.paymentstatus.depositamount')}
              value={paymentstatus.depositAmount.toLocaleString(undefined, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}
              />
            }

            <KeyValueColumns
              label={this.t('app.paymentstatus.paidamount')}
              value={paymentstatus.currentAmount.toLocaleString(undefined, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}
            />

            <div className="col-12 text-muted small">
              {this.t('app.paymentstatus.onSitePaymentNote', {
                toName: host.teamCompanyname,
                agencyPrice: paymentstatus.currentAmount.toLocaleString(undefined, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2}),
              })
              }
            </div>

            <div className="col-12 mt-4"></div>

            {paymentstatus.partialAmount > 0 &&
              <KeyValueColumns
                label={this.t('app.paymentstatus.prepayment')}
                value={paymentstatus.partialAmount.toLocaleString(undefined, {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                })}
              />
            }

            {paymentstatus.partialAmount > 0 && paymentstatus.partialPaymentTargetDate &&
              <KeyValueColumns
                label={this.t('app.paymentstatus.partialpayment_due_date')}
                value={localeDate(paymentstatus.partialPaymentTargetDate)}
              />
            }

            <div className="col-12 mt-4"></div>

            <KeyValueColumns
              label={this.t('app.paymentstatus.finalpayment')}
              value={paymentstatus.finalAmount.toLocaleString(undefined, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}
            />

            <KeyValueColumns
              label={this.t('app.paymentstatus.finalpayment_due_date')}
              value={localeDate(paymentstatus.finalPaymentTargetDate)}
            />

            {deposit.price > 0 && <div className="col-12 mt-4"></div>}

            {deposit.price > 0 &&
              <KeyValueColumns
                label={this.t('app.paymentstatus.depositpayment')}
                value={(deposit.currency === "EUR" ? deposit.price : deposit.convertedPrice).toLocaleString(undefined, {style: 'currency', currency: deposit.currency, minimumFractionDigits: 2})}
              />
            }

            {deposit.price > 0 && paymentstatus.depositPaymentTargetDate &&
              <KeyValueColumns
                label={this.t('app.paymentstatus.depositpayment_due_date')}
                value={localeDate(paymentstatus.depositPaymentTargetDate)}
              />
            }

            {reservationAttachedServicesSummary.atAgency.length > 0 && <>
              <div className="col-12 mt-4"></div>

              <KeyValueColumns
                label={this.t('app.paymentstatus.paymentsOnSiteAgency')}
                value={
                  reservationAttachedServicesSummary.atAgency.map((item) => (
                    <>
                      {item.total.toLocaleString(undefined, {
                        style: 'currency',
                        currency: item.currency,
                        minimumFractionDigits: 2,
                      })}
                      <br/>
                    </>
                  ))
                }
              />

              <KeyValueColumns
                label={this.t('app.paymentstatus.dueOnSite')}
                value={this.t('app.paymentstatus.dueOnAgency')}
              />
            </>
            }

            {reservationAttachedServicesSummary.atHost.length > 0 && <>
              <div className="col-12 mt-4"></div>

              <KeyValueColumns
                label={this.t('app.paymentstatus.paymentsOnSiteHost')}
                value={
                  <>
                    {reservationAttachedServicesSummary.atHost.map((item, index) => (
                      <Fragment key={index}>
                        {item.total.toLocaleString(undefined, {
                          style: 'currency',
                          currency: item.currency,
                          minimumFractionDigits: 2,
                        })}
                        <br/>
                      </Fragment>
                    ))
                    }
                  </>
                }
              />

              <KeyValueColumns
                label={this.t('app.paymentstatus.dueOnSite')}
                value={this.t('app.paymentstatus.dueOnHost')}
              />
            </>
            }

            <div className="col-12 mt-4"></div>

            <div className="col-12 text-muted small">{this.t('app.paymentstatus.paymentstatusinfo')}</div>

          </div>

          <div className="row">
            <div className="col-12 m-0 alert alert-info p-4">
              <div className="row">
                <div className="col-12">{this.t(
                  'app.tipps.paymentstatus.text')}</div>
                <div className="col-12 d-flex justify-content-end"><a
                  href="host" className="btn btn-info mt-2">
                  {this.t('app.tipps.paymentstatus.button')}
                </a></div>
              </div>
            </div>
          </div>

        </div>
        <BottomNav/>
      </div>
    );
  }
}

export default withNamespaces()(PaymentOverview);

