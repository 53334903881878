import React from 'react';
import AuthenticationService from './services/AuthenticationService';
import {withNamespaces} from 'react-i18next';
import BackendService from './services/BackendService';
import BottomNav from './elements/BottomNav';
import TopNav from './elements/TopNav';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Loading from './elements/Loading';
import {Helmet} from 'react-helmet';
import $ from 'jquery';
import GuestInfoDesktopNav from './elements/GuestInfoDesktopNav';
import GuestInfoMobileNav from './elements/GuestInfoMobileNav';
import GuestInfoText from './elements/GuestInfoText';
import GuestInfoPdf from './elements/GuestInfoPdf';
import i18n from './services/i18n';

const auth = new AuthenticationService();

class GuestInfo extends React.Component {

  constructor({t, props}) {
    super(props);

    this.updateState = this.updateState.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);

    this.backend = new BackendService();

    this.backend.registerCallback(this.updateState);

    this.state = {
      guestInfo: this.backend.get('guestInfo'),
      activeKey: 'key'
    };

    this.t = t;

    auth.requireLogin();
  }

  updateState() {
    this.setState({
      guestInfo: this.backend.get('guestInfo')
    });
  }

  componentDidMount() {
    this.installListener();
  }

  componentDidUpdate() {
    this.installListener();
  }

  installListener() {
    // first unregister any previous listeners and then re-add
    $('.guestinfo-item-desktop').off('click', this.handleItemClick).on('click', this.handleItemClick);
    $('.guestinfo-item-mobile').off('click', this.handleItemClick).on('click', this.handleItemClick);

  }

  handleItemClick(event) {
    let newcat = $(event.currentTarget).attr('data-itemcat');
    let newstate = this.state;
    newstate.activeKey = newcat;
    this.setState(newstate);

    $([document.documentElement, document.body]).animate({
      scrollTop: $('.guestinfo-content').offset().top - 50,
    }, 300);

  }

  render() {

    if (!this.state.guestInfo) {
      return (
        <div>
          <Helmet defer={false}>
            <title>Gast App - G&auml;steinformation</title>
          </Helmet>
          <TopNav isLoggedIn={false}/>
          <Loading/>
          <BottomNav/>
        </div>
      );
    }

    // key is fa icon code, value is the more logical translation code
    let categories = {
      'key': 'key',
      'car': 'travel',
      'wifi': 'wifi',
      'shield-virus': 'hygiene',
      'book': 'manuals',
      'gavel': 'rules',
      'copy': 'other',
      'gift': 'offers',
    };

    let catimages = {
      'key': 'schluessel.jpg',
      'car': 'anfahrt.jpg',
      'wifi': 'internet.jpeg',
      'shield-virus': 'hygiene.jpg',
      'book': 'anleitungen.jpg',
      'gavel': 'hausordnung.jpg',
      'copy': 'sonstiges.jpg',
      'gift': 'angebote.jpg',
    };

    // detect used cats
    let usedCategories = {}, item = {};
    for (let counter in this.state.guestInfo) {
      if (this.state.guestInfo.hasOwnProperty(counter)) {
        item = this.state.guestInfo[counter];
        if (item.category) {
          usedCategories[item.category] = true;
        }
      }
    }

    // clean list of cats to only keep used ones, note! external cats are only compared, not used!
    for (let cat in categories) {
      if (categories.hasOwnProperty(cat)) {
        if (usedCategories[categories[cat]] !== true) {
          delete categories[cat];
        }
      }
    }

    let activeKey = this.state.activeKey;
    if(!categories[this.state.activeKey]){
      let possibleKeys = Object.keys(categories);
      if(possibleKeys.length > 0) {
        activeKey = possibleKeys[0];
      }
    }

    let selLang = 'de';
    if(i18n.language && i18n.language!==''){
      let selLangTmp = i18n.language.split('-');
      selLang = selLangTmp[0].toLowerCase();
      if(selLang!=='de' && selLang!=='en'){
        selLang = 'de';
      }
    }
    let itemsToDisplay = [];
    for (let counter in this.state.guestInfo) {
      if (this.state.guestInfo.hasOwnProperty(counter)) {
        if (this.state.guestInfo[counter].category === categories[activeKey]
          && (!this.state.guestInfo[counter].lang || this.state.guestInfo[counter].lang === selLang)
        ) {
          itemsToDisplay.push(this.state.guestInfo[counter]);
        }
      }
    }

    return (
      <div>
        <Helmet defer={false}>
          <title>Gast App - G&auml;stemappe</title>
        </Helmet>
        <TopNav isLoggedIn={auth.isLoggedIn()}/>
        <div className="container p-0 overflow-hidden p-3 pb-5 mt-1">


          <div className="row mt-4 mb-3">
            <div className="col-12">
              <h2
                className="text-center text-darksecondary font-weight-bold">{this.t(
                'app.guestinfo.title')}</h2>
            </div>
          </div>

          <GuestInfoDesktopNav t={this.t} categories={categories} activeKey={activeKey}/>
          <GuestInfoMobileNav t={this.t} categories={categories} activeKey={activeKey}/>

          <div className={'row mt-1 d-block d-md-none d-xl-none guestinfo-content'}>
            <div className={'col-12 m-0 p-3 bg-lighter text-darksecondary text-nowrap'}>
              <b><FontAwesomeIcon icon={['fad', activeKey]}/> {this.t('app.guestinfo.mobile.category.' + categories[activeKey])}</b>
            </div>
            <div className={'col-12 m-0 p-0'}>
              <img className={'img-cover img-cover-small'} alt={''} src={'/assets/images/guestinfo/' + catimages[activeKey]}/>
            </div>
          </div>


          <div className={'row p-0 p-md-0 pl-md-2 mt-0'}>
            <div className={'col-12 m-0 p-0 pl-md-2'}>
              <div className={'row m-0 bg-lighter p-2 p-md-4 p-xl-4 text-darksecondary'}>
                <div className={'col-12'}>
                  <div className={'row'}>
                    <div className={'col-12 col-md-8'}>
                      <div className={'row'}>
                        {itemsToDisplay.map((data, i) => {
                          let response = <div key={'item-failed-' + i}/>;
                          switch (data.itemType) {
                            case 'text':
                              response = <GuestInfoText key={'item-' + i} t={this.t} {...data} />;
                              break;
                            case 'pdf':
                              response = <GuestInfoPdf key={'item-' + i} t={this.t} {...data} />;
                              break;
                            default:
                              break;
                          }
                          return response;
                        })
                        }
                      </div>
                    </div>
                    <div className={'d-none col-md-4 d-md-block d-xl-block'}>
                      <div className={'row'}>
                        <div className={'col-12 pr-0'}>
                          <img className={'img-cover'} alt={''} src={'/assets/images/guestinfo/' + catimages[activeKey]}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>
        <BottomNav/>
      </div>
    );
  }
}

export default withNamespaces()(GuestInfo);

