import React from 'react';
import AuthenticationService from './services/AuthenticationService';
import {withNamespaces} from 'react-i18next';
import BackendService from './services/BackendService';
import BottomNav from './elements/BottomNav';
import TopNav from './elements/TopNav';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from './elements/Loading';
import {Helmet} from "react-helmet";
import {localeDate} from "./services/Utils";
import EditAddonsUrlService from './services/EditAddonsUrlService';

const auth = new AuthenticationService();

const AddonsToHide= ["Endreinigung", "Kaution", "Kurtaxe"];

class Reservation extends React.Component {

  constructor({t, props}) {
    super(props);

    this.updateState = this.updateState.bind(this);

    this.backend = new BackendService();

    this.backend.registerCallback(this.updateState);

    this.state = {
      reservations: this.backend.get('reservations'),
      reservation_attached_services: this.backend.get('reservationAttachedServices')
    };

    this.t = t;

    auth.requireLogin();
  }

  updateState() {
    this.setState({
      reservations: this.backend.get('reservations'),
      reservation_attached_services: this.backend.get('reservationAttachedServices')
    });
  }

  render() {

    if (!this.state.reservations || !this.state.reservation_attached_services) {
      return (
        <div>
          <Helmet defer={false}>
            <title>Gast App - Buchung</title>
          </Helmet>
          <TopNav isLoggedIn={false} />
            <Loading />
          <BottomNav />
        </div>
      );
    }

    let reserv = this.state.reservations[0] || {};
    let services = this.state.reservation_attached_services || {};

    const arrival = new Date(reserv.arrival);
    const departure = new Date(reserv.departure);

    let timeDiff = Math.abs(departure.getTime() - arrival.getTime());
    const numberOfNights = Math.round(timeDiff / (1000 * 3600 * 24));

    return (
      <div>
         <Helmet defer={false}>
          <title>Gast App - Buchung</title>
        </Helmet>
        <TopNav isLoggedIn={auth.isLoggedIn()}/>
        <div className="container p-0 overflow-hidden p-3 pb-5 mt-1">


    <div className="row">
        <div className="col-12 mt-4 mb-3">
            <h2 className="text-center text-darksecondary font-weight-bold">{this.t('app.reservation.title')}</h2>
        </div>
    </div>
    <div className="row mb-4">
        <div className="col-6 font-weight-bolder">
            {this.t('app.reservation.bookingno')}
        </div>
        <div className="col-6 font-weight-bolder">
          {reserv.externalReservationNo}
        </div>
        <div className="col-6">
            {this.t('app.reservation.reserved_at')}
        </div>
        <div className="col-6">
          { localeDate(reserv.date_of_reservation) }
        </div>

        <div className="col-12 mt-4"></div>

        <div className="col-6 font-weight-bold">
            {this.t('app.reservation.arrival')}
        </div>
        <div className="col-6 font-weight-bold">
          { localeDate(reserv.arrival) }
        </div>



        <div className="col-6 font-weight-bold">
            {this.t('app.reservation.departure')}
        </div>
        <div className="col-6 font-weight-bold">
            { localeDate(reserv.departure) }
        </div>

        <div className="col-12 mt-4"></div>

        <div className="col-6">
            {this.t('app.reservation.nights')}
        </div>
        <div className="col-6">
          {numberOfNights}
        </div>

        <div className="col-6">
            {this.t('app.reservation.adults')}
        </div>
        <div className="col-6">
          {reserv.count_adult}
        </div>
        <div className="col-6">
            {this.t('app.reservation.children')}
        </div>
        <div className="col-6">
          {reserv.count_children}
          {reserv.child_age_csv &&
          <small>({reserv.child_age_csv} {this.t(
            'app.reservation.years')})</small>
          }

        </div>

    </div>
          { reserv.guest_registration_url &&
            <div className="row">
              <div className="col-12 m-0 alert alert-info p-4">
                <div className="row">
                  <div className="col-12">{this.t(
                    'app.tipps.reservation_guest.text')}</div>
                  <div className="col-12 d-flex justify-content-end"><a
                    href={ reserv.guest_registration_url } className="btn btn-info mt-2" target={'_blank'} rel="noopener noreferrer">
                    {this.t('app.tipps.reservation_guest.button')}
                  </a></div>
                </div>
              </div>
            </div>
          }
    <div className="row mb-4">

        <div className="col-12 mt-4">
            <h5 className="text-darksecondary font-weight-bold">{this.t('app.reservation.catering')}</h5>
            <FontAwesomeIcon icon={['fad', 'check-circle']} /> {this.t('app.reservation.cateringtype.' + reserv.catering)}
        </div>

        <div className="col-12 mt-4">
            <h5 className="text-darksecondary font-weight-bold">{this.t('app.reservation.service')}</h5>
        </div>

      {services.map((row, i) => {
        if (AddonsToHide.includes(row.name)) {
          return null;
        }
        return(<div key={i} className={'col-12'}>
          <div className={'row'}>
            <div className="col-4">
              {row.name}
            </div>
            <div className="col-8">
              <FontAwesomeIcon icon={['fad', 'check-circle']} /> {row.count} ({this.t('app.reservation.calculated_per')} {this.t('app.reservation.calculated_per_type.'+row.pricebase)})
            </div>
          </div>
        </div>);
      })}

      {reserv.editAddonsUrl && <div className="col-12 d-flex">
        <a href={EditAddonsUrlService.getHref(reserv.editAddonsUrl)} className="btn btn-info mt-2" target={'_blank'}
           rel="noopener noreferrer">
          {this.t('app.reservation.editAddons')}
        </a>
      </div>}

      {services.length < 1 &&

      <div className="col-12"><p
        className="fa fa-times-circle text-danger"></p> {this.t(
        'app.reservation.no_pz')}</div>
      }

      {reserv.reservation_notice &&
        <div>
      <div className="col-12 mt-4">
        <h5 className="text-darksecondary font-weight-bold">
          {this.t('app.reservation.reservation_notice')}
        </h5>
      </div>
        <div className="col-12 textwrap">
      {reserv.reservation_notice}
        </div>
        </div>
      }

    </div>


    <div className="row">
        <div className="col-12 m-0 alert alert-info p-4">
            <div className="row">
                <div className="col-12">{this.t('app.tipps.reservation.text')}</div>
                <div className="col-12 d-flex justify-content-end"><a href="host" className="btn btn-info mt-2">
                        {this.t('app.tipps.reservation.button')}
                    </a></div>
            </div>
        </div>
    </div>

        </div>
        <BottomNav/>
      </div>
    );
  }
}

export default withNamespaces()(Reservation);

