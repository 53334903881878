import React from 'react';
import AuthenticationService from './services/AuthenticationService';
import {withNamespaces} from 'react-i18next';
import ImprintNotice from './elements/ImprintNotice';
import TopNav from './elements/TopNav';
import BottomNav from './elements/BottomNav';
import {Helmet} from 'react-helmet';

const auth = new AuthenticationService();

class Imprint extends React.Component {

  constructor({t, props}) {
    super(props);
    this.t = t;
  }

  render() {
    let imprinttext = this.t('imprint.description');
    imprinttext.replace('\n', '\n');
    return (
      <div className="d-flex flex-column">
        <Helmet defer={false}>
          <title>Gast App - Impressum</title>
        </Helmet>
        <TopNav isLoggedIn={auth.isLoggedIn()}/>
        <div className={'container'}>
          <div className={'row'}>
            <div className={'col-12 mt-4 textwrap'}>

              {auth.isLoggedIn() &&
              <div className="row mt-md-3">
                <div className="col-12 m-0 alert alert-info p-4 pl-5 pr-5">
                  <div className="row">
                    <div className="col-12">
                      {this.t('app.tipps.imprint_1.text')}
                    </div>
                    <div className="col-12 d-flex justify-content-end"><a
                      href="host" className="btn btn-info mt-2">{this.t(
                      'app.tipps.imprint_1.button')}</a></div>
                  </div>
                </div>
              </div>
              }

              <br/>
              <h1>{this.t('imprint.headline')}</h1>
              <small>
                Dieser Service wird bereitgestellt von<br/>
                <br/>
                SECRA Bookings GmbH<br/>
                Wagrienring 2<br/>
                23730 Sierksdorf<br/>
                <br/>
                E-Mail: bookingservice@secra.de<br/>
                Web: www.secra.de<br/>
                <br/>
                Vertretungsberechtigung:<br/>
                Geschäftsführer Dipl.-Kfm. (FH) Christoph Rakel<br/>
                Geschäftsführer Dipl.-Ing. (FH) Sebastian Krüger<br/>
                <br/>
                Handelsregister:<br/>
                Amtsgericht Lübeck<br/>
                HRB 11307 HL<br/>
                <br/>
                Umsatzsteueridentifikationsnummer:<br/>
                DE 27 836 5438<br/>
              </small>
              <br/><br/>
              <small>
                <h3>{this.t('imprint.imagesources')}</h3>
                {this.t('imprint.imagesources_description')}<br />
                <br />
                <b>Unsplash</b><br />
                bence-boros-TMkrYpWW7kc-unsplash<br />
                sharon-mccutcheon-tn57JI3CewI-unsplash<br />
                nathan-fertig-FBXuXp57eM0-unsplash<br />
                <br />
                <b>Adobe Stock</b><br />
                #270753549 © didecs<br />
                #210737715 © Maria_Savenko<br />
                #348025619 © Pixel-Shot<br />
                #406982535 © New Africa<br />
                #334013047 © JenkoAtaman<br />
                #329376481 © dechevm<br />
              </small>
            </div>
          </div>
        </div>
        {!auth.isLoggedIn() &&
        <ImprintNotice/>
        }
        {auth.isLoggedIn() &&
        <BottomNav/>
        }
      </div>
    );
  }
}

export default withNamespaces()(Imprint);
