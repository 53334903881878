import React from 'react';
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImprintNotice from './elements/ImprintNotice';
import TopNav from './elements/TopNav';
import AuthenticationService from './services/AuthenticationService';
import {Helmet} from "react-helmet";

const auth = new AuthenticationService();

class Sorry extends React.Component {

  constructor({t, props}) {
    super(props);
    this.t = t;
  }

  render() {
    return (
      <div className="d-flex flex-column bg-light">
        <Helmet defer={false}>
          <title>Gast App - 404</title>
        </Helmet>
        <TopNav isLoggedIn={auth.isLoggedIn()} />
        <div className="container mt-3 p-3">
          <div className="row">
            <div className="col-12"><h3><FontAwesomeIcon icon={['fad', 'sad-tear']} />&nbsp;{this.t('sorry.headline')}</h3></div>

          </div>
          <div className="row mt-2">
            <div className="col-12">
              <span>{this.t('sorry.description')}</span>
            </div>
          </div>

        </div>

        <ImprintNotice />
      </div>
    );
  }
}

export default withNamespaces()(Sorry);
