import React from 'react';
import {withNamespaces} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const GuestInfoPdf = ({t, textTitle = 'Datei', pdfFileName, pdfFileTarget, hiddenUntil = null}) => {

  let dt = new Date(hiddenUntil);
  const options = {year: 'numeric', month: 'numeric', day: 'numeric'};
  let dts = hiddenUntil !== null ? dt.toLocaleDateString(undefined, options) : false;

  return (
    <div className={'col-12 col-md-6'}>
      <div className={'row mt-2 mb-2 mt-md-1 mr-md-1 mb-mt-1'}>
        <div className={'col-12 p-0 m-0'}>

          <b>{textTitle}</b>
          {dts && <div className={'guestinfo-hidden-badge float-left mr-1 p-1'}>
            <FontAwesomeIcon icon={['fad', 'lock-open']} className={'mr-1'}/>
            {t('app.guestinfo.hiddenUntil')} {dts}
          </div>
          }
        </div>
        {pdfFileTarget &&
          <a className={'col-12 btn btn-secondary text-left text-light'} target={'_blank'} href={pdfFileTarget} rel={'noreferrer noopener'}>
            <FontAwesomeIcon icon={['fad', 'file-pdf']} className={'mr-2'}/> {pdfFileName}
          </a>
        }
      </div>
    </div>
  );

};

export default withNamespaces()(GuestInfoPdf);
