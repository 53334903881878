import React from 'react';
import { withNamespaces } from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const GuestInfoMobileNav = ({ t , categories, activeKey }) => {

  return (
    <div className={'row d-sm-block d-xs-block d-md-none d-xl-none'}>
      <div className={'col-12 mt-3 guestinfo-item-container-mobile'}>
        <div className={'row'}>
          {Object.keys(categories).map((cat, i) => {
            return (
              <div data-itemcat={cat} key={'m_' + cat}
                   className={'guestinfo-item-mobile col-6 text-light p-0 text-center ' +
                     (activeKey !== cat ? 'guestinfo-item-mobile-small' : '')}
                   title={t('app.guestinfo.mobile.category.' + categories[cat])}>
                <div className={'m-1 p-4 text-nowrap text-truncate d-flex justify-content-center align-items-center'}>
                  <FontAwesomeIcon icon={['fad', cat]} size={'lg'} className={'m-1'}/>
                  <span className={'guestinfo-item-mobile-categorytext pl-2'}>{t('app.guestinfo.mobile.category.' + categories[cat])}</span>
                </div>
              </div>
            );
          })
          }
        </div>
      </div>
    </div>
  );

};

export default withNamespaces()(GuestInfoMobileNav);
