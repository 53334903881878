function log_error(level, module, msg){
  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    let currentdate = new Date();
    console[level]('[' + (currentdate.getDate() + "/"
      + (currentdate.getMonth() + 1) + "/"
      + currentdate.getFullYear() + " @ "
      + currentdate.getHours() + ":"
      + currentdate.getMinutes() + ":"
      + currentdate.getSeconds()) + '] ' + module + ' >> ', msg);
  }
}

export default log_error;
