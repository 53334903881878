import React from 'react';
import {withNamespaces} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LangSwitch from './LangSwitch';
import RegisterGuests from './RegisterGuests';
import CustomLinks from './CustomLinks';
import GuestInfoMenuItem from './GuestInfoMenuItem';
import EditAddons from "./EditAddons";

const TopNav = ({t, isLoggedIn, showTitle}) => {

  if (isLoggedIn) {

    return (

      <nav id="navi"
           className="navbar navbar-dark bg-secondary d-print-none fixed-top">
        <div className="nav_head_wrapper">
          <a className="navbar-brand" href="overview">
            <img src="suitcase.svg" width="30" height="30"
                 className="d-inline-block align-top"
                 alt=""/>

          </a>
          <div
            className="navbar-nav text-left d-xs-none d-sm-none d-md-inline-block d-xl-inline-block d-none ">
            <a href="overview"
               className="btn btn-info text-white  mr-2">
              <FontAwesomeIcon icon={['fad', 'book-open']}/>&nbsp;{t(
              'app.menu.index')}
            </a>
            <a href="reservation"
               className="btn btn-info text-white mr-2">
              <FontAwesomeIcon icon={['fad', 'address-card']}/>&nbsp;{t(
              'app.menu.reservation')}</a>
            <a href="property"
               className="btn btn-info text-white  mr-2">
              <FontAwesomeIcon icon={['fad', 'home']}/>&nbsp;{t(
              'app.menu.property')}
            </a>
            <a href="host"
               className="btn btn-info text-white ">
              <FontAwesomeIcon icon={['fad', 'user-friends']}/>&nbsp;{t(
              'app.menu.host')}</a>
          </div>

          <button className="navbar-toggler" type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent" aria-expanded="false"
                  aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">

          <ul
            className="navbar-nav mobile-nav mr-auto text-center scrollable-menu mt-2 p-2 row">

            <li className="nav-item col-12">
              <a className="nav-link" href="overview">{t('app.menu.index')}</a>
            </li>

            <li className="nav-item col-12">
              <a className="nav-link" href="reservation">{t(
                'app.menu.reservation')}</a>
            </li>
            <li className="nav-item col-12">
              <a className="nav-link" href="property">{t(
                'app.menu.property')}</a>
            </li>

            <li className="nav-item col-12">
              <a className="nav-link" href="host">{t('app.menu.host')}</a>
            </li>

            <li className="nav-item col-12">
              <a className="nav-link" href="weather">{t('app.menu.weather')}</a>
            </li>

            <GuestInfoMenuItem />

            <RegisterGuests/>

            <EditAddons/>

            <CustomLinks itemNo={0} />
            <CustomLinks itemNo={1} />
            <CustomLinks itemNo={2} />

          </ul>

          <div className="mt-2 text-right row">

            <div className="col-6 text-left">
              <LangSwitch/>
            </div>
            <div className="col-6 text-right">
              <a href="logout" id="logoutbutton"
                 className="btn btn-danger my-2 mr-2 my-sm-0">
                <FontAwesomeIcon icon={['fad', 'sign-out-alt']}/>&nbsp;{t(
                'app.menu.logout_button')}</a>
            </div>
          </div>

          <div className="row imprintrow pl-2">
            <div className={'col-12 text-center'}>
              <a href="imprint" className={'mr-4 text-darksecondary'}>{t(
                'app.menu.imprint')}</a>
              <a href="dataprotection" className={'text-darksecondary'}>{t(
                'app.menu.dataprotection')}</a>
            </div>
          </div>
        </div>

      </nav>

    );

  }
  else {

    return (

      <nav id="navi"
           className="navbar navbar-dark bg-secondary d-print-none fixed-top">
        <div className="nav_head_wrapper">
          <a className="navbar-brand" href="/">
            <img src="suitcase.svg" width="30" height="30"
                 className="d-inline-block align-top"
                 alt=""/>&nbsp;
            {
              showTitle && t('app.menu.title')
            }

          </a>
        </div>
      </nav>

    );

  }
};

export default withNamespaces()(TopNav);
