import React from 'react';
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BottomNav = ({ t, withGuestInfo = false }) => {
      return (

    <footer id="sticky-footer" className={'bg-light fixed-bottom d-print-none mt-auto d-xl-none d-md-none '+(withGuestInfo ? 'withGuestInfo' : '')}>
      {withGuestInfo &&
        <div className={'container-fluid'}>
          <div className={'row bottom-nav-guestinfo text-center p-0'}>
            <a href={'guestinfo'} className={'col-12 p-2 btn btn-info'}>
              <span className={'text-white'}>{ t('app.footer.guestinfo') }</span>
            </a>
          </div>
        </div>
      }
        <div className="container py-1">
            <div className="row bottom-nav">
                <a href="overview" className="col-3 pr-3 pl-3 pt-3 pb-1 text-center border-right overflow-hidden">
                        <FontAwesomeIcon icon={['fad', 'book-open']} transform="grow-16" style={{"--fa-primary-color": "#486467", "--fa-secondary-color": "#486467", "--fa-secondary-opacity": "1"}} />
                        <br/>{ t('app.footer.index') }</a>
                <a href="reservation" className="col-3 pr-3 pl-3 pt-3 pb-1 text-center border-right overflow-hidden">
                   <FontAwesomeIcon icon={['fad', 'address-card']} transform="grow-16" style={{"--fa-primary-color": "#486467", "--fa-secondary-color": "#486467"}} />
                   <br/>{ t('app.footer.reservation') }</a>
                <a href="property" className="col-3 pr-3 pl-3 pt-3 pb-1 text-center border-right overflow-hidden">
                   <FontAwesomeIcon icon={['fad', 'home']} transform="grow-16" style={{"--fa-primary-color": "#486467", "--fa-secondary-color": "#486467", "--fa-secondary-opacity": "1"}} />
                   <br/>{ t('app.footer.property') }</a>
                <a href="host" className="col-3 pr-3 pl-3 pt-3 pb-1 text-center overflow-hidden">
                  <FontAwesomeIcon icon={['fad', 'user-friends']} transform="grow-16" style={{"--fa-primary-color": "#486467", "--fa-secondary-color": "#486467"}} />
                  <br/>{ t('app.footer.host') }</a>
            </div>
        </div>
    </footer>

      );
};

export default withNamespaces()(BottomNav);
