/**
 * Parse given string as UTC date ignoring any timezone. Return local date
 * format in UTC timezone.
 *
 * @param {string} dateTimeString - 2023-05-10T00:00:00+02:00
 * @returns {string}
 */
export function localeDate(dateTimeString) {
  let date = new Date(dateTimeString.split('T')[0]);
  return date.toLocaleDateString(undefined, { timeZone: 'UTC' });
}
