import React from 'react';
import { withNamespaces } from 'react-i18next';

const AlertBox = ({ t , message }) => {
    if(message) {
      return (<p className="alert alert-danger">{t(message)}</p>);
    } else {
      return null;
    }
};

export default withNamespaces()(AlertBox);
