import React from 'react';
import AuthenticationService from './services/AuthenticationService';
import {withNamespaces} from 'react-i18next';
import BackendService from './services/BackendService';
import BottomNav from './elements/BottomNav';
import TopNav from './elements/TopNav';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WeatherWidget from './elements/WeatherWidget';
import Loading from './elements/Loading';
import PWAInstallPopup from './services/PWAInstallPopup';
import {Helmet} from "react-helmet";
import StatPixel from './elements/StatPixel';
import {localeDate} from "./services/Utils";
import EditAddonsUrlService from './services/EditAddonsUrlService';

const auth = new AuthenticationService();

class Overview extends React.Component {

  constructor({t, props}) {
    super(props);

    this.updateState = this.updateState.bind(this);
    this.installClockAnimation = this.installClockAnimation.bind(this);


    this.backend = new BackendService();

    this.backend.registerCallback(this.updateState);

    this.state = {
      reservations: this.backend.get('reservations'),
      properties: this.backend.get('properties'),
      propertyImages: this.backend.get('propertyImages'),
      payments: this.backend.get('payments'),
      reservation_attached_services: this.backend.get('reservationAttachedServices'),
      host: this.backend.get('host'),
      guestInfo: this.backend.get('guestInfo')
    };

    this.t = t;

    this.clockTime = [];

    auth.requireLogin();
  }

  updateState() {
    this.setState({
      reservations: this.backend.get('reservations'),
      properties: this.backend.get('properties'),
      propertyImages: this.backend.get('propertyImages'),
      payments: this.backend.get('payments'),
      reservation_attached_services: this.backend.get('reservationAttachedServices'),
      host: this.backend.get('host'),
      guestInfo: this.backend.get('guestInfo')
    });
  }

  installClockAnimation(){
    let that = this;
    $('.countdown').each(function(){
      var deadline = $(this).data('endtime');
      var elem = $(this);

      that.clockTime.push(setInterval(function(){
        var now = new Date().getTime();
        var t = deadline - now;
        var days = Math.floor(t / (1000 * 60 * 60 * 24));
        var hours = Math.floor((t%(1000 * 60 * 60 * 24))/(1000 * 60 * 60));
        var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((t % (1000 * 60)) / 1000);

        elem.find('.countdown-days').html(days);
        elem.find('.countdown-hours').html(hours);
        elem.find('.countdown-minutes').html(minutes);
        elem.find('.countdown-seconds').html(seconds);
      }, 500));

    });
  }

  componentWillUnmount() {
    this.clockTime.forEach(function(timerId){
      clearInterval(timerId);
    })
    this.clockTime = [];
  }

  componentDidMount() {
    this.installClockAnimation();
    $('.carousel').carousel();
  }

  componentDidUpdate() {
    this.installClockAnimation();
    $('.carousel').carousel();
  }

  render() {
    let guestInfo = true;
    if (!this.state.guestInfo || !auth.isLoggedIn() || this.state.guestInfo.length < 1) {
      guestInfo = false;
    }

    if (!this.state.reservations || !this.state.properties || !this.state.propertyImages || !this.state.payments) {
      return (
        <div>
          <Helmet defer={false}>
            <title>Gast App - Übersicht</title>
          </Helmet>
          <TopNav isLoggedIn={false} />
            <Loading />
          <BottomNav />
        </div>
      );
    }

    let reserv = this.state.reservations[0] || {};
    let property = this.state.properties[0] || {};
    let images = this.state.propertyImages || {};
    let payments = this.state.payments[0] || false;

    const hasImages = images.length > 0;

    const arrival = new Date(reserv.arrival);
    const isInPast = arrival < (new Date());

    return (
      <div>
        <Helmet defer={false}>
          <title>Gast App - Übersicht</title>
        </Helmet>
        <TopNav isLoggedIn={auth.isLoggedIn()}/>

        {auth.isLoggedIn() && !auth.isImpersonation() && reserv.trackingPixel1 &&
          <StatPixel href={reserv.trackingPixel1} />
        }

        {auth.isLoggedIn() && !auth.isImpersonation() && reserv.trackingPixel2 &&
          <StatPixel href={reserv.trackingPixel2} />
        }

        {auth.isLoggedIn() && !auth.isImpersonation() && reserv.trackingPixel3 &&
          <StatPixel href={reserv.trackingPixel3} />
        }

        <div className="container p-0 overflow-hidden pb-5 mt-1">
          <PWAInstallPopup />
          {!isInPast &&
          <div className="row p-md-1 p-xl-1">
            <div
              className="col-md-12 mt-3 mb-3 d-none d-md-block d-xl-block bg-lighter">
              <div
                className="row mt-4 mb-5 countdown countdown-desktop text-darksecondary"
                data-endtime={ arrival.getTime() }>
                <div className="col-12 mb-2 text-center">{this.t(
                  'app.index.countdown-top')}</div>

                <div className="col-2 pb-0"/>
                <div
                  className="col-2 pb-0 text-center countdown-number countdown-days">0
                </div>
                <div
                  className="col-2 pb-0 text-center countdown-number countdown-hours">0
                </div>
                <div
                  className="col-2 pb-0 text-center countdown-number countdown-minutes">0
                </div>
                <div
                  className="col-2 pb-0 text-center countdown-number countdown-seconds">0
                </div>
                <div className="col-2 pb-0"/>

                <div className="col-2"/>
                <div className="col-2 text-center small">{this.t(
                  'app.index.countdown-days')}</div>
                <div className="col-2 text-center small">{this.t(
                  'app.index.countdown-hours')}</div>
                <div className="col-2 text-center small">{this.t(
                  'app.index.countdown-minutes')}</div>
                <div className="col-2 text-center small">{this.t(
                  'app.index.countdown-seconds')}</div>
                <div className="col-2"/>

              </div>

            </div>
          </div>}



        <div className="container p-md-1 p-xl-1">
          <div className={"row"}>

          <div
            className="col-6 p-0 pr-3 mt-0 d-none d-md-block d-xl-block text-left holiday-teaser">
            <div className="col-12 pl-3 h-100">
              <div className="row h-100">
                <div className="col-12 p-0 mr-1">
                  <h4 className="m-0 p-4">{this.t(
                    'app.index.holiday-teaser-before')}&nbsp;
                    <b>{property.city}</b><br/>{this.t(
                      'app.index.holiday-teaser-from')}&nbsp;
                    <b>{ localeDate(reserv.arrival) }</b> {this.t(
                      'app.index.holiday-teaser-until')}&nbsp;
                    <b>{ localeDate(reserv.departure) }</b></h4>

                  <div className={'bg-lighter'}>
                  <WeatherWidget dataset={property.weather} />
                  </div>

                </div>

              </div>
            </div>
          </div>

          <div
            className="col-12 col-md-6 p-0 objectcarousel carousel slide align-middle">
            {hasImages &&
            <div className="carousel-inner">

              { images.map((imageSet, i) => {
                return (
                  <div key={i} className={ i<1 ? 'carousel-item active' : 'carousel-item' }>
                  <img className="d-block w-100 h-100"
                style={{"objectFit": "cover"}} src={ imageSet.url }
                border="0" alt=""/>
                  </div>
                );
              })
              }


            </div>
            }
            <div className="gradient d-md-none d-xl-none">
              {!isInPast &&
              <div className="row mt-4 text-white countdown phone-countdown"
                   data-endtime={ arrival.getTime() }>
                <div className="col-12 mb-2 small text-center">{this.t(
                  'app.index.countdown-top')}</div>


                <div className="col-2 pb-0"/>
                <div
                  className="col-2 pb-0 text-center countdown-number countdown-days">0
                </div>
                <div
                  className="col-2 pb-0 text-center countdown-number countdown-hours">0
                </div>
                <div
                  className="col-2 pb-0 text-center countdown-number countdown-minutes">0
                </div>
                <div
                  className="col-2 pb-0 text-center countdown-number countdown-seconds">0
                </div>
                <div className="col-2 pb-0"/>

                <div className="col-2"/>
                <div className="col-2 text-center small">{this.t(
                  'app.index.countdown-days')}</div>
                <div className="col-2 text-center small">{this.t(
                  'app.index.countdown-hours')}</div>
                <div className="col-2 text-center small">{this.t(
                  'app.index.countdown-minutes')}</div>
                <div className="col-2 text-center small">{this.t(
                  'app.index.countdown-seconds')}</div>
                <div className="col-2"/>
              </div>
              }
            </div>
          </div>

          {payments && !payments.isFullyPaid &&
          <div className="col-12 mt-md-3 mt-0 mb-0 mr-0 ml-0 p-0 bg-warning">
            <a href="paymentstatus"
               className="btn text-center text-black-50 bg-warning pr-2 pl-2 pt-3 pb-3 d-block">
              {this.t('app.index.payment_open')}
            </a>
          </div>
          }
          {payments && payments.isFullyPaid &&
          <div className="col-12 mt-md-3 mt-0 mb-0 mr-0 ml-0 p-0 bg-light">
            <a href="paymentstatus"
               className="btn text-center text-black-50 bg-secondary pr-2 pl-2 pt-3 pb-3 d-block">
              {this.t('app.index.payment_complete')}
            </a>
          </div>
          }

          {reserv.is_cancelled &&
          <div className="col-12 mt-0 mb-0 mr-0 ml-0 p-0 bg-danger">
            <a href="host"
               className="btn text-white bg-danger pr-2 pl-2 pt-3 pb-3 d-block">
              {this.t('app.index.reservation_cancelled_warning')}
            </a>
          </div>
          }

          {reserv.rating_url && isInPast && !auth.isImpersonation() &&
            <div className="col-12 mt-lg-4 mt-0 mb-0 mr-0 ml-0 p-0 bg-info">
              <a href={reserv.rating_url} target={'_blank'} rel={'noopener noreferrer'}
                 className="btn text-white bg-info pr-2 pl-2 pt-3 pb-3 d-block">
                {this.t('app.index.reservation_rating_link')}
              </a>
            </div>
          }

          <div
            className="col-12 col-md-6 d-md-none d-xl-none p-4 text-center holiday-teaser">
                              <h4 className="m-0">{this.t(
                    'app.index.holiday-teaser-before')}&nbsp;
                    <b>{property.city}</b><br/>{this.t(
                      'app.index.holiday-teaser-from')}&nbsp;
                    <b>{ localeDate(reserv.arrival) }</b> {this.t(
                      'app.index.holiday-teaser-until')}&nbsp;
                    <b>{ localeDate(reserv.departure) }</b></h4>
          </div>
            </div>
        </div>

        <div className="container d-none d-md-block d-xl-block">
          <div className="row mt-3 text-center quicknav-desktop">
            <div className="col-3 pr-2 pl-0"><a href="overview">
              <div className="bg-secondary p-4">
                <FontAwesomeIcon icon={['fad', 'book-open']} transform={"grow-35"} className={"mb-2 mt-3"} /><br />
                <span className="quicknav-text">{this.t('app.footer.index')}</span>
              </div>
            </a>
            </div>
            <div className="col-3 pr-2 pl-2"><a href="reservation">
              <div className="bg-secondary p-4">
                <FontAwesomeIcon icon={['fad', 'address-card']} transform={"grow-35"} className={"mb-2 mt-3"} /><br />
                <span
                  className="quicknav-text">{this.t(
                  'app.footer.reservation')}</span></div>
            </a>
            </div>
            <div className="col-3 pr-2 pl-2"><a href="property">
              <div className="bg-secondary p-4">
                <FontAwesomeIcon icon={['fad', 'home']} transform={"grow-35"} className={"mb-2 mt-3"} /><br />
                <span
                  className="quicknav-text">{this.t(
                  'app.footer.property')}</span></div>
            </a>
            </div>
            <div className="col-3 pl-2 pr-0"><a href="host">
              <div className="bg-secondary p-4">
                <FontAwesomeIcon icon={['fad', 'user-friends']} transform={"grow-35"} className={"mb-2 mt-3"} /><br />
                <span
                  className="quicknav-text">{this.t('app.footer.host')}</span>
              </div>
            </a>
            </div>
          </div>
        </div>

        <div className={'d-md-none d-xl-none d-xs-block d-sm-block bg-lighter'}>
          <WeatherWidget dataset={property.weather} />
        </div>


    <div className="container m-0 p-0 row mt-md-3">

        <div className={'col-12 col-md-'+(guestInfo ? '6 pr-md-4' : '12')+' m-0'}>
            <div className="row p-4 pl-5 pr-5 mb-0 alert alert-info">
                <div className="col-12">
                  {this.t('app.tipps.index_1.text')}
                </div>
                <div className="col-12 d-flex justify-content-end"><a href="host" className="btn btn-info mt-2">{this.t('app.tipps.index_1.button')}</a></div>
            </div>
        </div>

      {guestInfo &&
        <div className="col-12 col-md-6 m-0 d-none d-md-block pl-md-4">
          <div className="row  p-4 pl-5 pr-5 alert alert-info">
            <div className="col-12">
              {this.t('app.tipps.guestinfo.text')}
            </div>
            <div className="col-12 d-flex justify-content-end"><a href="guestinfo" className="btn btn-info mt-2">{this.t('app.tipps.guestinfo.button')}</a></div>
          </div>
        </div>
      }

      {reserv.editAddonsUrl &&
        <div className="col-12 col-md-12 m-0">
          <div className="row  p-4 pl-5 pr-5 alert alert-info">
            <div className="col-12">
              {this.t('app.tipps.editAddons.text')}
            </div>
            <div className="col-12 d-flex justify-content-end">
              <a href={EditAddonsUrlService.getHref(reserv.editAddonsUrl)} target={'_blank'} rel="noopener noreferrer" className="btn btn-info mt-2">
                {this.t('app.tipps.editAddons.button')}
              </a>
            </div>
          </div>
        </div>
      }
    </div>

        </div>
        <BottomNav withGuestInfo={!!guestInfo}/>
      </div>
    );
  }
}

export default withNamespaces()(Overview);
