import React from 'react';
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImprintNotice from './elements/ImprintNotice';
import TopNav from './elements/TopNav';
import PWAInstallPopup from './services/PWAInstallPopup';
import AuthenticationService from './services/AuthenticationService';

const auth = new AuthenticationService();

class LandingPage extends React.Component {

  constructor({t, props}) {
    super(props);
    this.t = t;

    if(auth.isLoggedIn()){
      window.location.href = 'overview';
      return;
    }
  }

  render() {
    return (
      <div className="d-flex flex-column bg-light">

        <TopNav showTitle={true} />
        <div className="container">
          <PWAInstallPopup />
          <div className={'row'}>
            <img className={'img-fluid mx-auto d-block landingpageimage'} src={'/assets/images/landingpage/nathan-fertig-FBXuXp57eM0-unsplash.jpg'} alt={'welcome header'}/>
          </div>

          <div className="row p-4">
            <div className="col-12 mt-4 text-darksecondary"><h1><b>{this.t('landingpage.headline')}</b></h1></div>
            <div className="col-12 col-md-6 mb-4">
              <span>{this.t('landingpage.description')}</span>
            </div>

            <div className={'col-12 col-md-6'}>
              <div className="row p-4 bg-lighter">
                <div className={'col-12 text-center text-white p-4 landingpageicon'}>
                  <FontAwesomeIcon icon={['fad', 'key']} transform={'grow-25'} style={{'--fa-primary-color': '#2f6f71', '--fa-secondary-color': '#2f6f71'}}/>
                </div>
                <div className="col-12 text-center">
                  <a href="/login" className="btn btn-secondary text-white">{this.t('landingpage.loginlink')}</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ImprintNotice />
      </div>
    );
  }
}

export default withNamespaces()(LandingPage);
