export const PlaceOfPayment = {
  ADVANCE_PAYMENT: 'advancePayment',
  AT_HOST_WITH_HOST_CURRENCY: 'atHostWithHostCurrency',
  AT_HOST_WITH_AGENCY_CURRENCY: 'atHostWithAgencyCurrency',
  AT_AGENCY_WITH_AGENCY_CURRENCY: 'atAgencyWithAgencyCurrency',
  AT_AGENCY_WITH_HOST_CURRENCY: 'atAgencyWithHostCurrency',
};

export const PaymentsOnSiteHost = [
  PlaceOfPayment.AT_HOST_WITH_HOST_CURRENCY,
  PlaceOfPayment.AT_HOST_WITH_AGENCY_CURRENCY,
];

export const PaymentsOnSiteAgency = [
  PlaceOfPayment.AT_AGENCY_WITH_AGENCY_CURRENCY,
  PlaceOfPayment.AT_AGENCY_WITH_HOST_CURRENCY,
];
